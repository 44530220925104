import {
  StoryblokServerComponent,
  storyblokEditable,
} from '@storyblok/react/rsc'
import type { TabsStoryblok } from '@vendure/marketing/util-storyblok-types'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@vendure/shadcn/ui'
import { cn } from '@vendure/shadcn/util'

interface TabsProps {
  blok: TabsStoryblok
  containerHorizontallyCentered?: boolean
}

export async function ContentTabs({
  blok,
  containerHorizontallyCentered,
}: TabsProps) {
  return (
    <Tabs
      defaultValue={blok.items?.at(0)?._uid}
      {...storyblokEditable(blok)}
    >
      <div
        className={cn(
          containerHorizontallyCentered ? 'justify-center md:flex' : 'block',
          'overflow-y-auto overflow-x-scroll md:overflow-x-auto',
        )}
      >
        <TabsList>
          {blok.items?.map((item) => {
            return (
              <TabsTrigger
                key={'tab-trigger-' + item._uid}
                value={item._uid}
              >
                {item.title}
              </TabsTrigger>
            )
          })}
        </TabsList>
      </div>

      {blok.items?.map((item) => {
        return (
          <TabsContent
            key={'tab-content-' + item._uid}
            className={cn(
              containerHorizontallyCentered ? 'mx-auto lg:max-w-screen-xl' : '',
              'py-10',
            )}
            value={item._uid}
          >
            {item.body?.map((nestedBlok) => (
              <StoryblokServerComponent
                blok={nestedBlok}
                key={nestedBlok._uid}
              />
            ))}
          </TabsContent>
        )
      })}
    </Tabs>
  )
}
