'use client'

import type { NpmMonthlyDownloadsStoryblok } from '@vendure/marketing/util-storyblok-types'
import { BaseStatistic } from '@vendure/shared/ui'
import type React from 'react'
import { useEffect, useState } from 'react'

interface INpmMonthlyDownloads {
  blok: NpmMonthlyDownloadsStoryblok
}

export const NpmMonthlyDownloads: React.FC<INpmMonthlyDownloads> = ({
  blok,
}) => {
  const [number, setNumber] = useState(0)

  useEffect(() => {
    const loadData = async () => {
      const res = await fetch(
        'https://api.npmjs.org/downloads/point/last-month/@vendure/core',
      )
      const data = await res.json()

      setNumber(data.downloads)
    }

    loadData()
  }, [])

  return (
    <BaseStatistic
      number={number}
      icon={blok.icon}
      label={blok.label}
    />
  )
}
