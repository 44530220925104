import { Button } from '@vendure/shadcn/ui'
import { ComponentProps, PropsWithChildren } from 'react'
import { CgSpinner } from 'react-icons/cg'

export function SubmitButton({
  loading = false,
  children,
  ...props
}: PropsWithChildren<ComponentProps<typeof Button> & { loading?: boolean }>) {
  return (
    <Button
      type="submit"
      {...props}
    >
      {loading ? <CgSpinner className="animate-spin" /> : children}
    </Button>
  )
}
