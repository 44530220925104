import { graphql } from '@vendure/hub/util-vendure-types'

export const licenseCardFragment = graphql(`
  fragment LicenseCard on License {
    id
    packageName
    status
    key
    startsAt
    expiresAt
    product {
      id
      name
      slug
      publisher {
        ... on Publisher {
          name
          websiteUrl
          supportUrl
        }
      }
    }
    order {
      id
      code
    }
    customFields {
      planName
    }
  }
`)
