'use client'

import type { MainHeroStoryblok } from '@vendure/marketing/util-storyblok-types'
import { motion, useScroll, useSpring, useTransform } from 'framer-motion'

import { Switch } from '@vendure/shadcn/ui'
import { cn } from '@vendure/shadcn/util'
import { StoryblokImage } from '@vendure/shared/ui'
import classNames from 'classnames'
import { useState } from 'react'
import { HiMoon, HiSun } from 'react-icons/hi'

export function MainHeroScreenshotCollection({
  blok,
}: {
  blok: MainHeroStoryblok
}) {
  const [showLight, setShowLight] = useState(false)
  const { scrollYProgress } = useScroll()
  const appearPoint = 0.05
  const rotateX = useTransform(scrollYProgress, [0, appearPoint], [40, 0])
  const rotateXSpring = useSpring(rotateX, { mass: 0.3, damping: 20 })
  const cubicEase = (t: number) => t * t * t
  const shadowSize = useTransform(
    useSpring(scrollYProgress, { mass: 0.1 }),
    [0, appearPoint],
    [5, 80],
    {
      ease: cubicEase,
    },
  )
  const boxShadow = useTransform(
    shadowSize,
    (input) => `var(--tw-color-primary-400) 0px 6px ${input}px`,
  )

  return (
    <div>
      <div
        className="relative px-2 lg:px-10"
        style={{ perspective: '2000px' }}
      >
        <motion.div
          style={{
            rotateX: rotateXSpring,
            boxShadow,
            borderRadius: '6px',
          }}
        >
          <div className="relative aspect-video overflow-hidden rounded-md">
            {blok.darkMode?.filename && (
              <StoryblokImage
                src={blok.darkMode.filename}
                className="absolute h-full w-full object-cover object-top"
                width={1920}
                height={1080}
                priority={true}
                alt=""
              />
            )}
            {blok.lightMode?.filename && (
              <StoryblokImage
                src={blok.lightMode.filename}
                width={1920}
                height={1080}
                priority={true}
                className={classNames([
                  'absolute h-full w-full object-cover object-top transition-all',
                  showLight ? 'opacity-100' : 'opacity-0',
                ])}
                alt=""
              />
            )}
          </div>
        </motion.div>
        <LeftScreenshot
          blok={blok}
          showLight={showLight}
        />
        <RightScreenshot
          blok={blok}
          showLight={showLight}
        />
      </div>
      <div className="mb-20 mt-5 flex flex-row items-center justify-center gap-2">
        <HiMoon opacity={showLight ? 0.5 : 1} />
        <Switch
          aria-label="Switch dark to light mode"
          onCheckedChange={(val) => {
            setShowLight(val)
          }}
        />
        <HiSun opacity={showLight ? 1 : 0.5} />
      </div>
    </div>
  )
}

const LeftScreenshot = (props: {
  blok: MainHeroStoryblok
  showLight: boolean
}) => {
  const { blok, showLight } = props
  const { scrollYProgress } = useScroll()
  const opacity = useTransform(scrollYProgress, [0.05, 0.06], [0, 1])
  const bottom = useTransform(
    scrollYProgress,
    [0.05, 0.12],
    // [isDesktop ? -340 : -100, isDesktop ? -160 : -70] TODO: check how to make responsive,
    [-340, -160],
  )
  const opacitySpring = useSpring(opacity, {
    stiffness: 50,
    mass: 2,
    restDelta: 0.001,
  })
  const bottomSpring = useSpring(bottom, { mass: 0.3, damping: 20 })

  return (
    <motion.div
      className={cn([
        'shadow-primary-400/20 absolute -left-2 z-10 hidden overflow-hidden rounded-md border-[10px] shadow-2xl transition-all md:-left-[2vw] md:block lg:-left-[6vw]',
        showLight ? 'border-white' : 'border-[rgb(30,42,52)]',
        'w-[40vw] lg:w-[200px] xl:w-[300px]',
        'h-[41vw] lg:h-[210px] xl:h-[320px]',
      ])}
      style={{
        translateY: bottomSpring,
        opacity: opacitySpring,
      }}
    >
      <>
        {blok.darkLeft?.filename && (
          <StoryblokImage
            src={blok.darkLeft.filename}
            className="absolute"
            width={837}
            height={0}
            alt=""
          />
        )}
        {blok.lightLeft?.filename && (
          <StoryblokImage
            src={blok.lightLeft.filename}
            className={cn([
              'absolute transition-all',
              showLight ? 'opacity-100' : 'opacity-0',
            ])}
            width={837}
            height={0}
            alt=""
          />
        )}
      </>
    </motion.div>
  )
}

const RightScreenshot = (props: {
  blok: MainHeroStoryblok
  showLight: boolean
}) => {
  const { blok, showLight } = props
  const { scrollYProgress } = useScroll()
  const opacity = useTransform(scrollYProgress, [0.02, 0.03], [0, 1])

  const bottom = useTransform(
    scrollYProgress,
    [0.05, 0.1],
    //[isDesktop ? -300 : -140, isDesktop ? -40 : -80] TODO: Check how to make that responsive,
    [-300, -80],
  )
  const opacitySpring = useSpring(opacity, {
    stiffness: 50,
    mass: 2,
    restDelta: 0.001,
  })
  const bottomSpring = useSpring(bottom, { mass: 0.3, damping: 20 })
  return (
    <motion.div
      className={cn([
        'shadow-primary-400/20 absolute -right-[6rem] z-10 hidden overflow-hidden rounded-md border-[10px] shadow-2xl transition-all md:-right-[1vw] md:block lg:-right-[3vw]',
        showLight ? 'border-white' : 'border-[rgb(30,42,52)]',
        'w-[60vw] lg:w-[400px] xl:w-[500px]',
        'h-[30vw] lg:h-[190x] xl:h-[245px]',
      ])}
      style={{
        translateY: bottomSpring,
        opacity: opacitySpring,
      }}
    >
      <>
        {blok.darkRight?.filename && (
          <StoryblokImage
            src={blok.darkRight.filename}
            width={837}
            height={0}
            alt=""
            className="absolute"
          />
        )}
        {blok.lightRight?.filename && (
          <StoryblokImage
            src={blok.lightRight.filename}
            width={837}
            height={0}
            alt=""
            className={cn([
              'absolute transition-all',
              showLight ? 'opacity-100' : 'opacity-0',
            ])}
          />
        )}
      </>
    </motion.div>
  )
}
