'use client'

import { activeCustomerDoc } from '@vendure/hub/data-access/server'
import { ResultOf } from 'gql.tada'
import { PropsWithChildren, createContext, useContext } from 'react'

const ActiveCustomerContext = createContext<
  ResultOf<typeof activeCustomerDoc> | undefined
>(undefined)

export function ActiveCustomerProvider({
  children,
  activeCustomer,
}: PropsWithChildren & {
  activeCustomer: ResultOf<typeof activeCustomerDoc>
}) {
  return (
    <ActiveCustomerContext.Provider value={activeCustomer}>
      {children}
    </ActiveCustomerContext.Provider>
  )
}

export const useActiveCustomer = () => {
  const activeCustomer = useContext(ActiveCustomerContext)
  if (!activeCustomer) {
    throw new Error(
      'useActiveCustomer must be used within a ActiveCustomerProvider',
    )
  }
  return activeCustomer
}
