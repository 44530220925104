import { getStoryblokApi } from '@vendure/marketing/util/server'

export const getCaseStudies = async (
  language: string,
  categories: string[] = [],
  locations: string[] = [],
  insideStoryblok = false,
) => {
  const storyblokApi = getStoryblokApi()
  const orQueries = []

  for (const category of categories) {
    orQueries.push({ category: { like: `*${category}*` } })
  }

  for (const location of locations) {
    orQueries.push({ location: { like: `*${location}*` } })
  }

  const { data } = await storyblokApi.get('cdn/stories', {
    language,
    content_type: 'caseStudy',
    sort_by: 'sort_by_date:desc',
    resolve_relations: ['caseStudy.customer'],
    filter_query: {
      __or: orQueries,
    },
  })

  return data.stories ?? []
}
