import { graphql } from '@vendure/hub/util-vendure-types'
import { clientApi } from '@vendure/shared/data-access-vendure'
import { activeOrderFragment } from '../fragments/active-order'

export const adjustOrderLineDoc = graphql(
  `
    mutation AdjustOrderLine($orderLineId: ID!, $quantity: Int!) {
      adjustOrderLine(orderLineId: $orderLineId, quantity: $quantity) {
        __typename
        ...ActiveOrder
        ... on ErrorResult {
          errorCode
          message
        }
      }
    }
  `,
  [activeOrderFragment],
)

export function adjustOrderLine(variables: {
  lineId: string
  quantity: number
}) {
  return clientApi.request(adjustOrderLineDoc, {
    orderLineId: variables.lineId,
    quantity: variables.quantity,
  })
}
