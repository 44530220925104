import { graphql } from '@vendure/hub/util-vendure-types'
import { serverApi } from '@vendure/shared/data-access-vendure'
import { activeOrderFragment } from '../fragments/active-order'
import { activeOrderForCheckoutFragment } from '../fragments/active-order-for-checkout'

export const activeOrderForCheckoutDoc = graphql(
  `
    query ActiveOrderForCheckout {
      activeOrder {
        ...ActiveOrder
        ...ActiveOrderForCheckout
      }
    }
  `,
  [activeOrderForCheckoutFragment, activeOrderFragment],
)

export function getActiveOrderForCheckout() {
  return serverApi.request(activeOrderForCheckoutDoc)
}
