import type { ArticleStoryblok } from '@vendure/marketing/util-storyblok-types'
import { getStoryblokApi } from '@vendure/marketing/util/server'
import type { StoryblokStory } from 'storyblok-generate-ts'

export const loadArticles = async (
  locale = 'en',
  page = 1,
  perPage = 6,
  insideStoryblok = false,
): Promise<{
  articles: Array<StoryblokStory<ArticleStoryblok>>
  total: string | null
}> => {
  const storyblokApi = getStoryblokApi()
  const IS_DEV = process.env.NODE_ENV === 'development'

  const { data, headers } = await storyblokApi.get('cdn/stories', {
    language: locale,
    version: insideStoryblok || IS_DEV ? 'draft' : 'published',
    content_type: 'article',
    resolve_relations: ['article.category', 'article.author'],
    sort_by: 'sort_by_date:desc',
    page,
    per_page: perPage,
  })

  return {
    articles: (data.stories ?? []) as unknown as Array<
      StoryblokStory<ArticleStoryblok>
    >,
    total: headers.total,
  }
}
