import { AssetStoryblok } from '@vendure/marketing/util-storyblok-types'
import type { ImageProps } from 'next/image'
import Image from 'next/image'

export type CustomImageProps = ImageProps & {
  image?: AssetStoryblok
}

export const StoryblokImage: React.FC<CustomImageProps> = ({
  src,
  width,
  height,
  quality,
  image,
  ...props
}) => {
  const filters: string[] = []

  if (typeof src === 'string' && src.includes('.svg')) {
    return (
      <Image
        unoptimized={true}
        src={src}
        width={width}
        height={height}
        {...props}
      />
    )
  }

  let srcProp = src

  if (image?.focus) {
    filters.push(`focal(${image.focus})`)
  }

  if (quality) {
    filters.push(`quality:${quality}`)
  }

  const widthAsNumber = Number(width)

  if (width) {
    // max 2000px width
    width = widthAsNumber > 2000 ? 2000 : width

    const processedSrc = `${src}/m/${String(width)}x${String(height)}`

    if (filters.length > 0) {
      srcProp = `${processedSrc}/filters:${filters.join(',')}`
    } else {
      srcProp = processedSrc
    }
  } else {
    srcProp = `${src}/m/smart`
  }

  return (
    <Image
      unoptimized={true}
      src={srcProp}
      width={width}
      height={height}
      {...props}
    />
  )
}
