'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { DefaultError, useMutation } from '@tanstack/react-query'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  useToast,
} from '@vendure/shadcn/ui'
import { cn } from '@vendure/shadcn/util'
import { ComponentProps } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { PreventHubspotFormTracking } from '../atoms'

const SelfServeDemoFormSchema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  company: z.string().optional(),
  role: z.enum(['developer', 'manager', 'other']),
})

export type SelfServeDemoFormSchemaType = z.infer<
  typeof SelfServeDemoFormSchema
>

interface SelfServeDemoFormProps extends ComponentProps<'form'> {}

export function SelfServeDemoForm({
  className,
  ...props
}: SelfServeDemoFormProps) {
  const form = useForm<SelfServeDemoFormSchemaType>({
    resolver: zodResolver(SelfServeDemoFormSchema),
  })
  const { mutateAsync, isPending } = useMutation<
    unknown,
    DefaultError,
    SelfServeDemoFormSchemaType
  >({
    mutationKey: ['selfServeDemoForm'],
    mutationFn: async (data) => {
      const res = await fetch('/api/self-serve-demo-form', {
        method: 'POST',
        body: JSON.stringify(data),
      })
      return await res.json()
    },
  })
  const { toast } = useToast()

  async function onSubmit(data: SelfServeDemoFormSchemaType) {
    try {
      await mutateAsync(data)

      form.reset()

      toast({
        title: 'Inquiry sent',
        description: 'We will get back to you soon',
        variant: 'default',
      })
    } catch (e) {
      toast({
        title: 'An error occurred',
        description: 'Please try again later',
        variant: 'destructive',
      })
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn('space-y-2', className)}
        {...props}
      >
        <PreventHubspotFormTracking></PreventHubspotFormTracking>
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Your role *</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex flex-col space-y-1"
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="developer" />
                      </FormControl>
                      <FormLabel className="font-normal">Developer</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="manager" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        Manager, Executive, ...
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="other" />
                      </FormControl>
                      <FormLabel className="font-normal">Other</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        ></FormField>
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>First name *</FormLabel>
                <FormControl>
                  <Input
                    placeholder="John"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        ></FormField>
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Last name * </FormLabel>
                <FormControl>
                  <Input
                    placeholder="Doe"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        ></FormField>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Email address *</FormLabel>
                <FormControl>
                  <Input
                    type={'email'}
                    placeholder="john.doe@acme.com"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        ></FormField>
        <FormField
          control={form.control}
          name="company"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Company</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Acme Inc."
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        ></FormField>
        <div>
          <Button
            disabled={isPending}
            variant="lead"
            type="submit"
            className="mt-6"
          >
            Get free demo
          </Button>
        </div>
      </form>
    </Form>
  )
}
