'use client'

import { useMutation } from '@tanstack/react-query'
import { createPortalSession } from '@vendure/hub/data-access/server'
import { readFragment } from '@vendure/hub/util-vendure-types'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@vendure/shadcn/ui'
import { cn } from '@vendure/shadcn/util'
import { CustomLink } from '@vendure/shared/ui'
import { formatDate } from '@vendure/shared/util'
import { licenseCardFragment } from '@vendure/user-account/data-access'
import { FragmentOf } from 'gql.tada'
import { EllipsisVerticalIcon } from 'lucide-react'
import Link from 'next/link'
import { useState } from 'react'
import { InstallDialog } from './install-dialog'

interface ILicenseCard {
  license: FragmentOf<typeof licenseCardFragment>
}

const statuses = {
  ACTIVE: 'text-green-600 bg-green-950/30 ring-green-600/30',
  EXPIRED: 'text-yellow-800 bg-yellow-950/30 ring-yellow-600/20',
}

export const LicenseCard: React.FC<ILicenseCard> = ({ license }) => {
  const licenseData = readFragment(licenseCardFragment, license)
  const [installDialogOpen, setInstallDialogOpen] = useState(false)
  const createPortalSessionMutation = useMutation({
    mutationFn: createPortalSession,
    onSuccess: (data) => {
      if (data.createPortalSession) {
        window.open(data.createPortalSession, '_blank')
      } else {
        console.error('No portal session was created')
      }
    },
  })

  return (
    <li
      key={licenseData.id}
      className="flex items-center justify-between gap-x-6 px-6 py-5"
    >
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <p className="font-semibold leading-6 text-white">
            {licenseData.product.name}{' '}
            {licenseData.customFields?.planName
              ? `(${licenseData.customFields?.planName})`
              : ''}
          </p>
          <p
            className={cn(
              statuses[licenseData.status],
              'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
            )}
          >
            {licenseData.status}
          </p>
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5">
          <Link
            href={`/hub/${licenseData.product.slug}`}
            className="whitespace-nowrap font-mono"
          >
            {licenseData.packageName}
          </Link>
          {licenseData.product.publisher && (
            <>
              <Separator />
              <a
                className="truncate"
                href={licenseData.product.publisher.websiteUrl ?? '#'}
              >
                By {licenseData.product.publisher.name}
              </a>
            </>
          )}
          {licenseData.status === 'ACTIVE' && licenseData.expiresAt && (
            <>
              <Separator />
              <span>Renews {formatDate(licenseData.expiresAt)}</span>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <Button
          variant="secondary"
          onClick={() => setInstallDialogOpen(true)}
        >
          Install
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="link">
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem asChild>
              {/* TODO: add default support url */}
              <CustomLink
                href={licenseData.product.publisher?.supportUrl ?? '#'}
              >
                Support
              </CustomLink>
            </DropdownMenuItem>
            {licenseData.order && (
              <DropdownMenuItem
                onClick={() =>
                  licenseData.order &&
                  createPortalSessionMutation.mutate(licenseData.order.code)
                }
              >
                Invoice
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <InstallDialog
        license={license}
        isOpen={installDialogOpen}
        onOpenChange={setInstallDialogOpen}
      />
    </li>
  )
}

const Separator: React.FC = () => (
  <svg
    viewBox="0 0 2 2"
    className="h-0.5 w-0.5 fill-white/50"
  >
    <circle
      cx={1}
      cy={1}
      r={1}
    />
  </svg>
)
