'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  useToast,
} from '@vendure/shadcn/ui'
import { SubmitButton } from '@vendure/shared/ui'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { requestPasswordResetEmail } from './actions'

export function ForgotPasswordForm() {
  const { toast } = useToast()

  const FormSchema = z.object({
    email: z.string().email(),
  })
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  })

  async function onSubmit(values: z.infer<typeof FormSchema>) {
    const res = await requestPasswordResetEmail(values.email)
    console.log({ res })
    if (res.requestPasswordReset?.__typename === 'Success') {
      form.reset({
        email: '',
      })
      toast({
        variant: 'success',
        title: 'Successfully sent email',
        description: 'Please check your inbox for further instructions',
      })
    } else {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: res.requestPasswordReset?.message ?? 'An error occurred',
      })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mt-4 grid gap-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>E-mail address</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormItem>
            <SubmitButton
              loading={form.formState.isSubmitting}
              className="w-full"
              variant="default"
              type="submit"
              disabled={!form.formState.isValid}
            >
              Request reset email
            </SubmitButton>
          </FormItem>
        </div>
      </form>
    </Form>
  )
}
