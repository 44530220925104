import { graphql } from '@vendure/hub/util-vendure-types'
import { activeOrderFragment } from './active-order'
import { orderCustomerFragment } from './order-customer'

export const activeOrderForCheckoutFragment = graphql(
  `
    fragment ActiveOrderForCheckout on Order {
      ...ActiveOrder
      customer {
        ...OrderCustomer
        organization {
          id
          name
          contactEmailAddress
          invoiceEmailAddresses
        }
      }
      billingAddress {
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        countryCode
      }
      payments {
        id
        amount
        method
      }
    }
  `,
  [activeOrderFragment, orderCustomerFragment],
)
