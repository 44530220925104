'use client'

import { cn } from '@vendure/shadcn/util'
import { render } from './rich-text-renderer'
import { RichTextProps } from './types'

export function RichText(props: RichTextProps) {
  const { document, containerProps } = props

  return (
    <div
      {...props.containerProps}
      className={cn(
        'rich-text-container prose prose-slate prose-headings:font-accent prose-headings:text-white',
        containerProps?.className,
      )}
    >
      {document && render(document)}
    </div>
  )
}
