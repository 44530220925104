'use client'

import type { AssetStoryblok } from '@vendure/marketing/util-storyblok-types'
import type React from 'react'
import FilePlayer from 'react-player/file'

interface IBackgroundVideo {
  asset: AssetStoryblok
  blurred?: boolean
  videoPoster?: AssetStoryblok
}

export const BackgroundVideo: React.FC<IBackgroundVideo> = ({
  asset,
  blurred = false,
  videoPoster,
}) => {
  return (
    <>
      <div className="absolute left-0 top-0 z-[2] min-h-full min-w-full bg-black/40 backdrop-blur-md" />
      <FilePlayer
        url={asset.filename}
        loop={true}
        playsinline={true}
        playing={true}
        muted={true}
        config={{
          attributes: {
            style: { objectFit: 'cover', width: '100%', height: '100%' },
          },
        }}
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          minWidth: '100%',
          minHeight: '100%',
          top: '50%',
          transform: 'translate(0, -50%)',
          left: 0,
          zIndex: 1,
          objectFit: 'cover',
        }}
      />
    </>
  )
}
