import { graphql } from '@vendure/hub/util-vendure-types'

export const subscriptionPlanFragment = graphql(`
  fragment SubscriptionPlan on PublicSubscriptionPlan {
    id
    code
    name
    initialPrice
    recurringPrice
    scheduleSummary
    trialPeriodDays
    currencyCode
    schedule {
      intervalFrequency
      intervalUnit
      term
    }
  }
`)
