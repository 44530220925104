'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import { RichText } from '@vendure/marketing/feature-rich-text'
import type {
  PersonStoryblok,
  PrimaryContactTeaserStoryblok,
} from '@vendure/marketing/util-storyblok-types'
import {
  ActionButton,
  GradientCard,
  StoryblokImage,
  TitleWithAccent,
} from '@vendure/shared/ui'
import { MotionProps, motion } from 'framer-motion'
import { useIntl } from 'react-intl'
import type { StoryblokStory } from 'storyblok-generate-ts'

interface IPrimaryContactTeaser {
  blok: PrimaryContactTeaserStoryblok
}

const ContactPerson = (contactPerson: PersonStoryblok, dense = false) => {
  const image = contactPerson.image
  const intl = useIntl()
  return (
    <div className="flex flex-col items-center border-l border-slate-700 py-4 text-center md:py-0">
      {image?.filename && (
        <GradientCard outerProps={{ className: 'rounded-full mb-5' }}>
          <StoryblokImage
            width={300}
            height={300}
            className=" h-[100px] w-[100px]"
            style={{ objectFit: 'cover', objectPosition: 'center' }}
            src={image.filename}
            alt={contactPerson.name ?? ''}
          />
        </GradientCard>
      )}
      <span className="mb-2 block text-sm font-medium text-white/60">
        {intl.formatMessage({ id: 'contact_teaser.contact_person_label' })}
      </span>
      <span className="block text-xl font-semibold text-white">
        {contactPerson.title && <span>{contactPerson.title} </span>}
        {contactPerson.name}
      </span>
      <span className="text-md block text-white/80">{contactPerson.role}</span>
    </div>
  )
}

function PrimaryContactTeaser({ blok }: IPrimaryContactTeaser) {
  const contactPersonStory = blok.contactPerson as
    | StoryblokStory<PersonStoryblok>
    | undefined

  const motionProps: MotionProps = {
    initial: {
      opacity: 0,
      y: 20,
      boxShadow: `rgba(23, 193, 255, 0) 0px 1px 0px`,
    },
    whileInView: {
      opacity: 1,
      y: 0,
      boxShadow: `rgba(23, 193, 255, 0.2) 0px 1px 90px`,
    },
    exit: {
      opacity: 0,
      y: -20,
      boxShadow: ` rgba(23, 193, 255, 0) 0px 1px 0px`,
    },
    transition: { ease: 'easeInOut' },
  }

  return (
    <motion.div
      className="mx-auto lg:max-w-screen-lg"
      {...motionProps}
      {...storyblokEditable(blok)}
    >
      <GradientCard
        containerProps={{
          className: 'bg-slate-900 py-6 px-4 lg:py-10 lg:px-10',
        }}
      >
        <div className="grid grid-cols-1 items-center gap-6 md:grid-cols-[2fr_1fr]">
          <div>
            <TitleWithAccent
              title={blok.title}
              containerProps={{
                className: 'text-4xl',
              }}
              tag="span"
            />
            {blok.text && (
              <RichText
                containerProps={{ className: 'mt-4' }}
                document={blok.text}
              />
            )}
            <div className="mt-8 flex items-center justify-start gap-1">
              {blok.buttons?.map((item) => {
                return (
                  <ActionButton
                    key={item._uid}
                    {...item}
                  />
                )
              })}
            </div>
          </div>
          <div>
            {blok.contactPerson &&
              contactPersonStory &&
              ContactPerson(contactPersonStory.content, blok.dense)}
            {blok.teaserImage?.filename && (
              <div className="radius-md relative -bottom-[30px] w-[120%] overflow-hidden shadow-lg md:absolute md:-bottom-[-10px] md:-right-[10px] md:w-[35%]">
                <StoryblokImage
                  className="h-auto w-full"
                  width={800}
                  height={600}
                  src={blok.teaserImage.filename}
                  alt={blok.teaserImage.alt ?? ''}
                />
              </div>
            )}
          </div>
        </div>
      </GradientCard>
    </motion.div>
  )
}

export default PrimaryContactTeaser
