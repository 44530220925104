import { graphql } from '@vendure/hub/util-vendure-types'

export const activeOrderFragment = graphql(`
  fragment ActiveOrder on Order {
    __typename
    id
    code
    couponCodes
    state
    currencyCode
    totalQuantity
    subTotalWithTax
    shippingWithTax
    total
    totalWithTax
    discounts {
      description
      amountWithTax
    }
    lines {
      id
      unitPriceWithTax
      quantity
      linePriceWithTax
      productVariant {
        id
        name
        sku
        product {
          slug
        }
      }
      customFields {
        planId
      }
      subscriptionPlan {
        id
        name
        code
        initialPrice
        recurringPrice
        trialPeriodDays
        scheduleSummary
        currencyCode
        schedule {
          intervalUnit
        }
      }
      featuredAsset {
        id
        preview
      }
    }
    shippingLines {
      shippingMethod {
        description
      }
      priceWithTax
    }
  }
`)
