import { graphql } from '@vendure/hub/util-vendure-types'
import { getAuthHeaders, serverApi } from '@vendure/shared/data-access-vendure'
import { VariablesOf } from 'gql.tada'
import { licenseCardFragment } from '../fragments/license-card.fragment'

export const getAccountLicensesDataDoc = graphql(
  `
    query GetAccountLicenses($options: LicenseListOptions) {
      activeCustomer {
        id
        organization {
          licenses(options: $options) {
            items {
              id
              ...LicenseCard
            }
            totalItems
          }
        }
      }
    }
  `,
  [licenseCardFragment],
)

export async function getAccountLicensesData(
  variables: VariablesOf<typeof getAccountLicensesDataDoc>,
) {
  return serverApi.request(
    getAccountLicensesDataDoc,
    variables,
    await getAuthHeaders(),
  )
}
