'use client'

import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/navigation'
import { logout } from './actions'

export function SignOutButton() {
  const router = useRouter()
  const queryClient = useQueryClient()
  // TODO: queryClient.invalidateQueries({ queryKey: KEY_ACTIVE_CUSTOMER })
  return (
    <form action={logout}>
      <button type="submit">Sign Out</button>
    </form>
  )
}
