import { graphql } from '@vendure/hub/util-vendure-types'
import { clientApi } from '@vendure/shared/data-access-vendure'
import { assetFragment } from '../fragments/asset'
import { subscriptionPlanFragment } from '../fragments/subscription-plan'

export const getProductDetailDoc = graphql(
  `
    query GetProductDetail($slug: String!) {
      product(slug: $slug) {
        id
        name
        featuredAsset {
          ...Asset
        }
        assets {
          ...Asset
        }
        description
        publisher {
          id
          name
          supportUrl
          websiteUrl
          supportLanguages
        }
        customFields {
          downloadCount
          lastPublish
          latestVersion
          packageName
          repositoryUrl
          registry
          compatibility
          readme
          changelog
          docs
          displayApiDocsLink
        }
        facetValues {
          facet {
            id
            code
            name
          }
          id
          code
          name
          customFields {
            color
            description
          }
        }
        variants {
          id
          name
          sku
          subscriptionPlans {
            id
            ...SubscriptionPlan
          }
        }
      }
    }
  `,
  [assetFragment, subscriptionPlanFragment],
)

export async function getProductDetail(slug: string) {
  return clientApi.request(getProductDetailDoc, {
    slug: slug,
  })
}
