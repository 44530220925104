'use client'

import type { ButtonStoryblok } from '@vendure/marketing/util-storyblok-types'
import { Button } from '@vendure/shadcn/ui'
import { useMemo } from 'react'
import { FiArrowRight, FiArrowUpRight } from 'react-icons/fi'
import { CustomLink } from './CustomLink'

export const ActionButton: React.FC<ButtonStoryblok> = ({
  text,
  icon,
  target,
  variant,
}) => {
  const IconComponent = useMemo(() => {
    switch (icon) {
      case 'arrow-right':
        return FiArrowRight
      case 'arrow-right-up':
        return FiArrowUpRight
      default:
    }
  }, [icon])

  const btnVariant = useMemo(() => {
    switch (variant) {
      case 'light':
        return 'secondary'
      case 'primary':
        return 'lead'
      case 'dark':
      default:
        return 'default'
    }
  }, [variant])

  return (
    <Button
      asChild
      variant={btnVariant}
      className="rounded-full"
    >
      <CustomLink href={target?.cached_url}>
        {text}
        {IconComponent && <IconComponent className="ml-1 h-4 w-4" />}
      </CustomLink>
    </Button>
  )
}
