import { graphql } from '@vendure/hub/util-vendure-types'
import { clientApi } from '@vendure/shared/data-access-vendure'
import { VariablesOf } from 'gql.tada'
import { hubCardSearchFragment } from '../fragments/hub-card-search'

export const searchProductsDoc = graphql(
  `
    query SearchProducts($input: SearchInput!) {
      search(input: $input) {
        items {
          ...HubProductCard
        }
        facets {
          facet {
            id
            code
            name
          }
        }
        facetCounts {
          fieldName
          counts {
            count
            value
          }
          stats {
            avg
            max
            min
            sum
          }
        }
        totalItems
      }
    }
  `,
  [hubCardSearchFragment],
)

export function searchProducts(
  variables: VariablesOf<typeof searchProductsDoc>,
) {
  return clientApi.request(searchProductsDoc, variables)
}
