'use client'

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@vendure/shadcn/ui'
import { usePathname, useSearchParams } from 'next/navigation'
import { ComponentProps } from 'react'

interface SearchParamsPaginationProps {
  totalPages: number
  currentPage: number
}

export function SearchParamsPagination({
  totalPages,
  currentPage,
  ...props
}: SearchParamsPaginationProps & ComponentProps<typeof Pagination>) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const pages = Array.from(
    { length: totalPages > 3 ? 3 : totalPages },
    (_, i) => i + currentPage - 1,
  )

  const nextParams = new URLSearchParams(searchParams)
  nextParams.set('page', (currentPage + 1).toString())
  const prevParams = new URLSearchParams(searchParams)
  prevParams.set('page', (currentPage - 1).toString())

  return (
    <Pagination {...props}>
      <PaginationContent>
        {currentPage > 1 && (
          <>
            <PaginationItem>
              <PaginationPrevious
                href={`${pathname}?${prevParams.toString()}`}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
          </>
        )}
        {pages
          .filter((page) => page > 0)
          .map((page) => {
            const newParams = new URLSearchParams(searchParams)
            newParams.set('page', page.toString())
            return (
              <PaginationItem key={page}>
                <PaginationLink
                  isActive={page === currentPage}
                  href={`${pathname}?${newParams.toString()}`}
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
            )
          })}

        {currentPage < totalPages && (
          <>
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
            <PaginationItem>
              <PaginationNext href={`${pathname}?${nextParams.toString()}`} />
            </PaginationItem>
          </>
        )}
      </PaginationContent>
    </Pagination>
  )
}
