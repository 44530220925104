import { graphql } from '@vendure/hub/util-vendure-types'
import { serverApi } from '@vendure/shared/data-access-vendure'

export const logoutDoc = graphql(`
  mutation Logout {
    logout {
      success
    }
  }
`)

export function logOut() {
  return serverApi.request(logoutDoc)
}
