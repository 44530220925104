'use client'

import { HomeIcon } from '@radix-ui/react-icons'
import { cn } from '@vendure/shadcn/util'
import { CustomLink } from '@vendure/shared/ui'
import { CogIcon } from 'lucide-react'
import { usePathname } from 'next/navigation'
import { GrLicense } from 'react-icons/gr'
import { LuPackageCheck } from 'react-icons/lu'

export default function AccountSidebar() {
  const path = usePathname()

  const navigation = [
    {
      name: 'Dashboard',
      href: '/account',
      icon: HomeIcon,
    },
    {
      name: 'Registry',
      href: '/account/registry',
      icon: LuPackageCheck,
    },
    {
      name: 'Licenses',
      href: '/account/licenses',
      icon: GrLicense,
    },
  ]

  return (
    <div className="flex w-[20rem] flex-col gap-y-5 overflow-y-auto px-2 pb-4">
      <nav className="flex flex-1 flex-col">
        <ul
          role="list"
          className="flex flex-1 flex-col gap-y-7"
        >
          <li>
            <ul
              role="list"
              className="-mx-2 space-y-1"
            >
              {navigation.map((item) => (
                <li key={item.name}>
                  <CustomLink
                    href={item.href}
                    className={cn(
                      path === item.href
                        ? 'bg-gray-800 text-white'
                        : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                    )}
                  >
                    <item.icon
                      className="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    {item.name}
                  </CustomLink>
                </li>
              ))}
            </ul>
          </li>
          <li className="mt-auto">
            <CustomLink
              href="/account/settings"
              className={cn(
                'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white',
                path == '/account/settings' && 'text-primary-400 bg-white/10',
              )}
            >
              <CogIcon
                className="h-6 w-6 shrink-0"
                aria-hidden="true"
              />
              Settings
            </CustomLink>
          </li>
        </ul>
      </nav>
    </div>
  )
}
