import { graphql } from '@vendure/hub/util-vendure-types'
import { getAuthHeaders, serverApi } from '@vendure/shared/data-access-vendure'

export const activeCustomerDoc = graphql(`
  query ActiveCustomer {
    activeCustomer {
      id
      title
      firstName
      lastName
      emailAddress
    }
  }
`)

export async function getActiveCustomer() {
  return serverApi.request(activeCustomerDoc, undefined, await getAuthHeaders())
}
