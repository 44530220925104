import { graphql } from '@vendure/hub/util-vendure-types'
import { clientApi } from '@vendure/shared/data-access-vendure'
import { VariablesOf } from 'gql.tada'

const createOnboardingLinkDoc = graphql(`
  mutation CreateOnboardingLink($accountId: String!) {
    createOnboardingLink(accountId: $accountId)
  }
`)

export async function createOnboardingLink(
  variables: VariablesOf<typeof createOnboardingLinkDoc>,
) {
  return clientApi.request(createOnboardingLinkDoc, variables)
}
