import { graphql } from '@vendure/hub/util-vendure-types'
import { serverApi } from '@vendure/shared/data-access-vendure'
import { ResultOf } from 'gql.tada'
import { print } from 'graphql'

const loginDoc = graphql(`
  mutation LogIn(
    $emailAddress: String!
    $password: String!
    $rememberMe: Boolean!
  ) {
    login(
      username: $emailAddress
      password: $password
      rememberMe: $rememberMe
    ) {
      __typename
      ... on CurrentUser {
        id
        identifier
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`)

export async function logIn(variables: {
  emailAddress: string
  password: string
  rememberMe: boolean
}) {
  return serverApi.rawRequest<ResultOf<typeof loginDoc>>(
    print(loginDoc),
    variables,
  )
}
