'use client'

import type { CompareSliderStoryblok } from '@vendure/marketing/util-storyblok-types'
import { GradientCard } from '@vendure/shared/ui'
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider'

interface ICompareSlider {
  blok: CompareSliderStoryblok
}

export function CompareSlider({ blok }: ICompareSlider) {
  return (
    <GradientCard>
      <ReactCompareSlider
        itemOne={
          <ReactCompareSliderImage
            src={blok.image1?.filename}
            alt={blok.image1?.alt}
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            src={blok.image2?.filename}
            alt={blok.image2?.alt}
          />
        }
      />
    </GradientCard>
  )
}
