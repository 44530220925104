'use client'

import type {
  HeaderMenuStoryblok,
  NavDropdownItemStoryblok,
} from '@vendure/marketing/util-storyblok-types'
import { Button } from '@vendure/shadcn/ui'
import classNames from 'classnames'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import _ from 'lodash'
import type { LinkProps } from 'next/link'
import { usePathname } from 'next/navigation'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import { CustomLink, Logo, StoryblokImage } from '../atoms'
import { DropdownMenu } from '../molecules'
import { useNavContext } from '../providers/NavProvider'

interface NavbarProps extends PropsWithChildren {
  blok?: HeaderMenuStoryblok
  activeBanner?: boolean
}

export const Navbar: React.FC<NavbarProps> = ({
  blok,
  activeBanner = false,
  children,
}) => {
  const pathname = usePathname()
  const intl = useIntl()
  const { setDropdownOpen } = useNavContext()
  const [dropdownContent, setDropdownContent] = useState<string>()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const navbarRef = useRef<HTMLDivElement | null>(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const isBlurred = useMemo(() => scrollPosition > 50, [scrollPosition])

  const trackScrollPosition = () => {
    const position = window.scrollY
    setScrollPosition(position)
  }

  useEffect(() => {
    setScrollPosition(window.scrollY)
    window.addEventListener('scroll', trackScrollPosition)

    return () => window.removeEventListener('scroll', trackScrollPosition)
  }, [])

  useEffect(() => {
    setDropdownContent(undefined)
    setDropdownOpen?.(false)
  }, [pathname])

  const setDropdownOpenDebounced = useCallback(
    _.debounce((val) => {
      setDropdownOpen?.(val)
    }, 150),
    [],
  )

  const container = {
    open: {
      height: 'auto',
    },
    close: {
      height: 0,
    },
  }

  const content = {
    open: {
      x: 0,
      opacity: 1,
    },
    close: {
      x: -25,
      opacity: 0,
    },
  }

  return (
    <>
      <nav
        onMouseLeave={() => {
          setDropdownOpenDebounced.cancel()
          setDropdownContent(undefined)
          setDropdownOpen?.(false)
        }}
        ref={navbarRef}
        className={classNames({
          'z-overlay relative py-3 transition-all md:py-2': true,
        })}
      >
        {isBlurred && (
          <div className="absolute inset-0 z-[-1] backdrop-blur-[20px] backdrop-filter" />
        )}
        <div className="container">
          <div className="flex flex-row items-center justify-between gap-6">
            {blok?.logo?.filename && <Logo file={blok.logo} />}
            <div className="hidden flex-1 items-center justify-start gap-6 lg:flex">
              {blok?.navItems?.map((item, i) => {
                const isFirst = i === 0
                const isActive =
                  item.target?.cached_url === ''
                    ? false
                    : pathname?.includes(item.target?.cached_url ?? '')
                const isDropdownItem = item.component === 'navDropdownItem'

                const btnProps: LinkProps = isDropdownItem
                  ? {
                      href: '#',
                      onMouseEnter: () => {
                        setDropdownOpenDebounced.cancel()
                        setDropdownContent(item._uid)
                        setDropdownOpen?.(true)
                      },
                    }
                  : {
                      onMouseEnter: () => {
                        setDropdownContent(undefined)
                        setDropdownOpenDebounced(false)
                      },
                      href: `/${String(item.target?.cached_url)}`,
                    }

                return (
                  <Button
                    asChild
                    variant="link"
                    key={item._uid}
                    className={classNames({
                      'font-sm px-0 font-semibold text-white transition-all':
                        true,
                      'text-primary-500':
                        (isActive && !isDropdownItem) ||
                        dropdownContent === item._uid,
                      'hover:text-primary-500 no-underline hover:no-underline':
                        true,
                      'pl-0': isFirst,
                    })}
                  >
                    <CustomLink {...btnProps}>{item.navTitle}</CustomLink>
                  </Button>
                )
              })}
            </div>
            <AnimatePresence>
              <motion.div
                style={{
                  right: 0,
                  position: 'absolute',
                  left: 0,
                  top: (navbarRef.current?.clientHeight ?? 55) + 1,
                }}
                initial="close"
                variants={container}
                animate={dropdownContent ? 'open' : 'close'}
                exit="close"
                className="overflow-hidden"
                transition={{ when: 'beforeChildren' }}
              >
                <div ref={containerRef}>
                  <AnimatePresence mode="wait">
                    <LayoutGroup>
                      {blok?.navItems
                        ?.filter((item) => item.component === 'navDropdownItem')
                        .map((item) => {
                          if (dropdownContent === item._uid) {
                            return (
                              <div
                                className="overflow-hidden"
                                key={item._uid}
                              >
                                <motion.div variants={content}>
                                  <DropdownMenu
                                    blok={item as NavDropdownItemStoryblok}
                                  />
                                </motion.div>
                              </div>
                            )
                          }
                        })}
                    </LayoutGroup>
                  </AnimatePresence>
                </div>
              </motion.div>
            </AnimatePresence>
            <div className="flex items-center justify-end">
              <div className="hidden lg:flex">
                {blok?.contactButtonTarget?.cached_url && (
                  <Button
                    asChild
                    variant="link"
                    size="sm"
                  >
                    <CustomLink href={blok.contactButtonTarget.cached_url}>
                      {intl.formatMessage({
                        id: 'navigation.cta.contact_us',
                      })}
                    </CustomLink>
                  </Button>
                )}
                {blok?.startButtonTarget?.cached_url && (
                  <Button
                    asChild
                    variant="lead"
                    size="sm"
                  >
                    <CustomLink href={blok.startButtonTarget.cached_url}>
                      {intl.formatMessage({ id: 'navigation.cta.demo' })}
                    </CustomLink>
                  </Button>
                )}
              </div>
              <CustomLink
                className="mx-4"
                href="https://github.com/vendure-ecommerce/vendure"
                target="_blank"
              >
                <StoryblokImage
                  width={100}
                  height={100}
                  alt="GitHub star counter"
                  src="https://img.shields.io/github/stars/vendure-ecommerce/vendure.svg?style=social"
                />
              </CustomLink>
              {children}
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
