'use client'

import type { GithubStarsStoryblok } from '@vendure/marketing/util-storyblok-types'
import { BaseStatistic } from '@vendure/shared/ui'
import type React from 'react'
import { useEffect, useState } from 'react'

interface IGithubStars {
  blok: GithubStarsStoryblok
}

export const GithubStars: React.FC<IGithubStars> = ({ blok }) => {
  const [number, setNumber] = useState(0)

  useEffect(() => {
    const loadData = async () => {
      const res = await fetch(
        'https://api.github.com/repos/vendure-ecommerce/vendure',
      )
      const data = await res.json()

      setNumber(data.stargazers_count)
    }

    loadData()
  }, [])

  return (
    <BaseStatistic
      number={number}
      icon={blok.icon}
      label={blok.label}
    />
  )
}
