import { graphql } from '@vendure/hub/util-vendure-types'

/**
 * This fragment must be in a separate file to the HubCard client component so that it can
 * be imported into server components without triggering this error:
 * ```
 * Error: Cannot access definitions.Symbol(Symbol.iterator) on the server. You cannot
 * dot into a client module from a server component. You can only pass the imported name through.
 * ```
 */
export const hubCardSearchFragment = graphql(`
  fragment HubProductCard on SearchResult {
    productId
    productName
    slug
    productAsset {
      id
      preview
      focalPoint {
        x
        y
      }
    }
    description
    currencyCode
    customMappings {
      downloadCount
      lastPublish
      latestVersion
      packageName
      repositoryUrl
      registry
      isPaid
      integrationType {
        name
        code
        color
      }
      monthlyPrice
      publisherName
    }
    facetValueIds
  }
`)
