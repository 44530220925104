import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@vendure/shadcn/ui'
import { CopyBlock, TextLink } from '@vendure/shared/ui'
import { licenseCardFragment } from '@vendure/user-account/data-access'
import { FragmentOf, readFragment } from 'gql.tada'
import type React from 'react'

interface IInstallDialog {
  license: FragmentOf<typeof licenseCardFragment>
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

export const InstallDialog: React.FC<IInstallDialog> = ({
  license,
  isOpen,
  onOpenChange,
}) => {
  const licenseData = readFragment(licenseCardFragment, license)
  const installCommand = `npm install ${licenseData.packageName}`
  return (
    <Dialog
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Installing {licenseData.product.name}</DialogTitle>
          <DialogDescription>
            <div className="mt-2 space-y-6">
              <div>
                1. Ensure you have{' '}
                <TextLink href={`/account/registry`}>
                  set up access to the Vendure package registry
                </TextLink>
              </div>
              <div>
                2. Install the package
                <CopyBlock
                  text={installCommand}
                  customStyle={{ padding: '8px 6px' }}
                  language="bash"
                  showLineNumbers={false}
                ></CopyBlock>
              </div>
              <div>
                3. Configure the plugin according to the{' '}
                <TextLink href={`/hub/${licenseData.product.slug}`}>
                  documentation
                </TextLink>
              </div>
              <div>
                4. Add your license key to the plugin init options
                <CopyBlock
                  text={licenseData.key}
                  customStyle={{ padding: '8px 6px' }}
                  language="bash"
                  showLineNumbers={false}
                ></CopyBlock>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
