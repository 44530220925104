import { graphql } from '@vendure/hub/util-vendure-types'
import { getAuthHeaders, serverApi } from '@vendure/shared/data-access-vendure'

export const getAccountPageDataDoc = graphql(`
  query GetAccountPageData {
    activeCustomer {
      id
      createdAt
      phoneNumber
      addresses {
        id
        city
      }
      title
      firstName
      lastName
      emailAddress
      organization {
        id
        name
        invoiceEmailAddresses
        contactEmailAddress
        taxId
        username
        password

        customFields {
          taxIdStatus
          verifiedAddress
        }
      }
    }
  }
`)

export async function getAccountSettingsData() {
  return serverApi.request(
    getAccountPageDataDoc,
    undefined,
    await getAuthHeaders(),
  )
}
