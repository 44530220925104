import type {
  PersonCardStoryblok,
  PersonStoryblok,
} from '@vendure/marketing/util-storyblok-types'
import { GradientCard, StoryblokImage } from '@vendure/shared/ui'
import type { StoryblokStory } from 'storyblok-generate-ts'

interface PersonCardStoryblokWithPerson extends PersonCardStoryblok {
  person?: StoryblokStory<PersonStoryblok>
}

interface PersonCardProps {
  blok: PersonCardStoryblokWithPerson
}

export function PersonCard({ blok }: PersonCardProps) {
  const person = blok.person?.content

  return (
    <div className="flex flex-col items-center text-center">
      {person?.image?.filename && (
        <GradientCard
          outerProps={{ className: 'rounded-full w-40 h-40 overflow-hidden' }}
        >
          <StoryblokImage
            width={300}
            height={300}
            className="h-full w-full object-cover object-center"
            src={person.image.filename}
            alt={person.image.alt ?? person.name ?? ''}
          />
        </GradientCard>
      )}
      <p className="my-2 text-2xl font-bold leading-tight text-white">
        {person?.name}
      </p>
      <p>{person?.role}</p>
    </div>
  )
}
