import { storyblokEditable } from '@storyblok/react/rsc'
import type { LogoGridStoryblok } from '@vendure/marketing/util-storyblok-types'
import { StoryblokImage } from '@vendure/shared/ui'
import classNames from 'classnames'
import Link from 'next/link'

interface ILogoGrid {
  blok: LogoGridStoryblok
}

export function LogoGrid({ blok }: ILogoGrid) {
  return (
    <div
      className="grid grid-cols-2 items-center justify-center gap-6 md:flex md:gap-20"
      {...storyblokEditable(blok)}
    >
      {blok.items
        ?.filter((item) => item.image !== undefined)
        .map((item) => {
          const hasLink = item.link?.cached_url !== ''
          return (
            <div
              className="relative"
              key={item._uid}
              {...storyblokEditable(item)}
              {...(hasLink ? { as: Link, href: item.link?.cached_url } : {})}
            >
              <StoryblokImage
                alt={item.image!.alt ?? ''}
                src={item.image!.filename}
                height={400}
                width={400}
                className={classNames(
                  'm-auto h-[35px] w-auto',
                  blok.disableColorFilter ? '' : 'brightness-0 invert',
                )}
              />
            </div>
          )
        })}
    </div>
  )
}
