import { graphql } from '@vendure/hub/util-vendure-types'
import { clientApi, getAuthHeaders } from '@vendure/shared/data-access-vendure'

export const oneClickCheckoutDoc = graphql(`
  mutation OneClickCheckout($variantId: ID!, $planId: ID!) {
    oneClickCheckout(variantId: $variantId, planId: $planId)
  }
`)

export async function oneClickCheckout(variables: {
  variantId: string
  planId: string
}) {
  return clientApi.request(
    oneClickCheckoutDoc,
    {
      variantId: variables.variantId,
      planId: variables.planId,
    },
    await getAuthHeaders(),
  )
}
