import { PersonStoryblok } from '@vendure/marketing/util-storyblok-types'
import { StoryblokImage } from '../atoms'

interface AuthorsProps {
  authors?: PersonStoryblok[]
}

export function Authors({ authors }: AuthorsProps) {
  return (
    <div className="flex items-center space-x-4">
      <div className="flex items-center -space-x-4">
        {authors &&
          authors
            .filter((author) => author.image?.filename)
            .map((author) => {
              return (
                <StoryblokImage
                  className="border-dark h-12 w-12 rounded-full border-4 object-cover object-center"
                  src={author.image!.filename}
                  alt={author.name ?? ''}
                  image={author.image}
                  width={50}
                  height={50}
                ></StoryblokImage>
              )
            })}
      </div>
      <span className="text-white md:text-lg">
        {authors &&
          authors
            .map((author) => {
              return author.name
            })
            .join(', ')}
      </span>
    </div>
  )
}
