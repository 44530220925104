'use client'

import { getPrefixedLink } from '@vendure/marketing/util'
import type {
  ConfigStoryblok,
  NavDropdownItemLinkCardStoryblok,
  NavDropdownItemListColumnStoryblok,
  NavDropdownItemStoryblok,
  NavLinkStoryblok,
} from '@vendure/marketing/util-storyblok-types'
import { Button } from '@vendure/shadcn/ui'
import { cn } from '@vendure/shadcn/util'
import { AnimatePresence, motion } from 'framer-motion'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { MdArrowRight, MdClose, MdMenu } from 'react-icons/md'
import { CustomLink } from '../atoms'
import { useNavContext } from '../providers/NavProvider'

interface MobileMenuProps {
  blok: ConfigStoryblok
}

const MobileMenuContext = createContext<{
  dropdownOpenId: string | undefined
  setDropdownOpenId?: (openId: string) => void
}>({
  dropdownOpenId: undefined,
})
const useMobileMenuContext = () => useContext(MobileMenuContext)

export const MobileMenu: React.FC<MobileMenuProps> = ({ blok }) => {
  const [isOpen, setIsOpen] = useState(false)
  const headerMenu = blok.headerMenu?.at(0)
  const navLinks = headerMenu?.navItems ?? []
  const pathname = usePathname()
  const { setDropdownOpen } = useNavContext()

  const [dropdownOpenId, setDropdownOpenId] = useState<string | undefined>(
    undefined,
  )

  const toggleMenu = () => {
    setIsOpen(!isOpen)
    setDropdownOpen?.(!isOpen)
  }

  useEffect(() => {
    setIsOpen(false)
    setDropdownOpen?.(false)
  }, [pathname])

  return (
    <MobileMenuContext.Provider value={{ dropdownOpenId, setDropdownOpenId }}>
      <div className="block lg:hidden">
        <Button
          onClick={toggleMenu}
          variant="lead"
          className="z-overlay fixed bottom-4 right-4 shadow-xl"
        >
          {!isOpen && (
            <>
              <MdMenu className="text-md mr-1 h-6 w-6" />
              <span className="text-sm">Menu</span>
            </>
          )}
          {isOpen && (
            <>
              <MdClose className="text-md mr-1 h-6 w-6" />
              <span className="text-sm">Close</span>
            </>
          )}
        </Button>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="z-banner"
              style={{
                position: 'fixed',
                right: 0,
                bottom: 0,
              }}
              initial={{
                borderRadius: '99999px',
                height: 0,
                width: 0,
              }}
              animate={{
                borderRadius: 0,
                height: '100vh',
                width: '100vw',
              }}
              exit={{
                borderRadius: '99999px',
                height: 0,
                width: 0,
              }}
            >
              <motion.div
                style={{ height: '100%', width: '100%' }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="flex min-h-screen w-full flex-col items-start justify-center p-8">
                  <p className="mb-4 text-sm font-semibold">Menu</p>
                  <div className="flex w-full flex-col items-start justify-stretch">
                    {navLinks.map((item) => {
                      const hasSubMenu = item.subItems?.length > 0

                      return (
                        <MobileMenuItem
                          key={item._uid}
                          item={item}
                        />
                      )
                    })}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </MobileMenuContext.Provider>
  )
}

interface MobileMenuItemProps {
  item: NavLinkStoryblok | NavDropdownItemStoryblok
  level?: number
}

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  item,
  level = 0,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasSubMenu =
    item.subItems?.length > 0 || item.component === 'navDropdownItem'
  const pathname = usePathname()
  const { dropdownOpenId, setDropdownOpenId } = useMobileMenuContext()

  const linkProps = {
    href: item.target?.cached_url,
    as: Link,
  }

  const toggleSubMenu = () => {
    const newState = !isOpen
    setIsOpen(newState)

    if (newState) {
      setDropdownOpenId?.(item._uid)
    }
  }

  useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  useEffect(() => {
    if (item._uid != dropdownOpenId) {
      setIsOpen(false)
    }
  }, [dropdownOpenId, item])

  const fontSize = useMemo(() => {
    switch (level) {
      case 1:
        return 'text-lg'
      case 2:
        return 'text-md'
      case 0:
      default:
        return 'text-xl'
    }
  }, [level])

  const color = useMemo(() => {
    switch (level) {
      case 1:
        return 'text-white'
      case 2:
        return 'text-white/60'
      case 0:
      default:
        return 'text-white'
    }
  }, [level])

  return (
    <>
      <div
        className="my-2 flex w-full items-center justify-between"
        {...(!hasSubMenu ? linkProps : { onClick: toggleSubMenu })}
      >
        <CustomLink
          href={getPrefixedLink(item.target)}
          className={cn([
            'hover:text-primary-400 focus:text-primary-400 font-semibold transition-colors',
            fontSize,
            level === 0 ? 'font-accent font-bold' : '',
            isOpen ? 'text-primary-400' : color,
          ])}
        >
          {item.navTitle}
        </CustomLink>
        {hasSubMenu && (
          <motion.div animate={{ rotate: isOpen ? 90 : 0 }}>
            <MdArrowRight className="h-6 w-6" />
          </motion.div>
        )}
      </div>
      {hasSubMenu && (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              style={{ overflow: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
            >
              <div className="mt-4 flex flex-col items-start gap-4 pb-2">
                {item.component === 'navLink' &&
                  item.subItems?.map((subItem) => {
                    return (
                      <MobileMenuItem
                        level={level + 1}
                        key={subItem._uid}
                        item={subItem}
                      />
                    )
                  })}
                {item.component === 'navDropdownItem' &&
                  item.columns?.map(
                    (
                      column:
                        | NavDropdownItemListColumnStoryblok
                        | NavDropdownItemLinkCardStoryblok,
                    ) => {
                      if (column.component === 'navDropdownItemListColumn') {
                        return (
                          <div key={column._uid}>
                            <p className="mb-1 text-xs uppercase tracking-wider text-white/60">
                              {column.title}
                            </p>
                            {column.linkItems?.map((linkItem) => (
                              <MobileMenuItem
                                level={level + 1}
                                key={linkItem._uid}
                                item={linkItem}
                              />
                            ))}
                          </div>
                        )
                      }
                    },
                  )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  )
}
