import { graphql } from '@vendure/hub/util-vendure-types'
import { getAuthHeaders, serverApi } from '@vendure/shared/data-access-vendure'
import { VariablesOf } from 'gql.tada'

export const verifyCustomerAccountDoc = graphql(`
  mutation VerifyCustomerAccount($token: String!) {
    verifyCustomerAccount(token: $token) {
      __typename
      ... on CurrentUser {
        id
        identifier
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`)

export async function verifyCustomerAccount(
  input: VariablesOf<typeof verifyCustomerAccountDoc>,
) {
  return serverApi.request(
    verifyCustomerAccountDoc,
    input,
    await getAuthHeaders(),
  )
}
