import { graphql } from '@vendure/hub/util-vendure-types'
import { clientApi } from '@vendure/shared/data-access-vendure'

export const facetListDoc = graphql(`
  query FacetList($options: FacetListOptions!) {
    facets(options: $options) {
      items {
        id
        name
        code
        values {
          id
          name
          code
          customFields {
            description
            color
          }
        }
      }
      totalItems
    }
  }
`)

export function getFacets() {
  return clientApi.request(facetListDoc, {
    options: {
      take: 50,
      filter: {
        code: { eq: 'category' },
      },
    },
  })
}
