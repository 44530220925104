import { graphql } from '@vendure/hub/util-vendure-types'
import { getAuthHeaders, serverApi } from '@vendure/shared/data-access-vendure'

export const activeCustomerAndOrderDoc = graphql(`
  query ActiveCustomerAndOrder {
    activeCustomer {
      id
      title
      firstName
      lastName
      emailAddress
      organization {
        id
        name
        contactEmailAddress
        invoiceEmailAddresses
      }
    }
    activeOrder {
      id
      code
      lines {
        id
      }
    }
  }
`)

export async function getActiveCustomerAndOrder() {
  return serverApi.request(
    activeCustomerAndOrderDoc,
    undefined,
    await getAuthHeaders(),
  )
}
