import { graphql } from '@vendure/hub/util-vendure-types'
import { getAuthHeaders, serverApi } from '@vendure/shared/data-access-vendure'

export const getAccountRegistryDataDoc = graphql(`
  query GetAccountPageData {
    activeCustomer {
      organization {
        username
        password
      }
    }
  }
`)

export async function getAccountRegistryData() {
  return serverApi.request(
    getAccountRegistryDataDoc,
    undefined,
    await getAuthHeaders(),
  )
}
