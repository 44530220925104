import { cn } from '@vendure/shadcn/util'
import React, { ComponentProps, ElementType, PropsWithChildren } from 'react'
import IntrinsicElements = React.JSX.IntrinsicElements

type HeadingProps = {
  as?:
    | keyof Pick<
        IntrinsicElements,
        'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p'
      >
    | ElementType
} & PropsWithChildren &
  ComponentProps<'h1'>

export function Heading({
  as = 'span',
  children,
  className,
  ...props
}: HeadingProps) {
  const HeadingTag = as
  return (
    <HeadingTag
      {...props}
      className={cn(
        'font-accent font-bold tracking-tight text-white',
        className,
      )}
    >
      {children}
    </HeadingTag>
  )
}
