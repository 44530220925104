import { getStoryblokApi } from '@vendure/marketing/util/server'
import type { StoryblokStory } from 'storyblok-generate-ts'

export const buildBreadcrumbStories = async <T>(
  slug: string,
  locale = 'de',
  rootSlug = '',
) => {
  const storyblokApi = getStoryblokApi()

  const parts = slug.split('/')
  let currentSlug = rootSlug
  const pages: Array<StoryblokStory<T>> = []

  // fetch home
  const { data } = await storyblokApi.get('cdn/stories/home', {
    version: 'published',
    language: locale,
  })

  pages.push(data.story)

  if (slug !== 'home') {
    for (const part of parts) {
      currentSlug += `/${part}`

      try {
        const { data } = await storyblokApi.get(`cdn/stories${currentSlug}`, {
          version: 'published',
          language: locale,
        })

        pages.push(data.story)
      } catch (error) {}
    }
  }

  return pages
}
