import { graphql } from '@vendure/hub/util-vendure-types'
import { serverApi } from '@vendure/shared/data-access-vendure'
import { activeOrderFragment } from '../fragments/active-order'

export const activeOrderDoc = graphql(
  `
    query ActiveOrder {
      activeOrder {
        ...ActiveOrder
      }
    }
  `,
  [activeOrderFragment],
)

export function getActiveOrder() {
  return serverApi.request(activeOrderDoc)
}
