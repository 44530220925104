import { graphql } from '@vendure/hub/util-vendure-types'
import { clientApi } from '@vendure/shared/data-access-vendure'

export const createCheckoutSessionDoc = graphql(`
  mutation CreateCheckoutSession {
    createCheckoutSession
  }
`)

export function createCheckoutSession() {
  return clientApi.request(createCheckoutSessionDoc)
}
