import { graphql } from '@vendure/hub/util-vendure-types'
import { clientApi } from '@vendure/shared/data-access-vendure'
import { VariablesOf } from 'gql.tada'

const authorizePublisherDoc = graphql(`
  mutation AuthorizePublisher($code: String!) {
    authorizePublisherAccount(code: $code)
  }
`)

export async function authorizePublisher(
  variables: VariablesOf<typeof authorizePublisherDoc>,
) {
  return clientApi.request(authorizePublisherDoc, variables)
}
