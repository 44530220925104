export interface Registry {
  unregister: () => void
}

export type Callable = Record<string, Function>

export type Subscriber = Record<string, Callable>

export interface IEventBus {
  dispatch<T>(event: string, arg?: T): void
  register(event: string, callback: Function): Promise<Registry>
}

export class EventBus implements IEventBus {
  private subscribers: Subscriber
  private static nextId = 0
  private static instance?: EventBus = undefined

  private constructor() {
    this.subscribers = {}
  }

  public static getInstance(): EventBus {
    if (this.instance === undefined) {
      this.instance = new EventBus()
    }

    return this.instance
  }

  public async dispatch<T>(event: string, arg?: T): Promise<void> {
    const subscriber = this.subscribers[event]

    if (subscriber === undefined) {
      return
    }

    for (const key of Object.keys(subscriber)) {
      await subscriber[key](arg)
    }
  }

  public async register(event: string, callback: Function): Promise<Registry> {
    const id = this.getNextId()
    if (!this.subscribers[event]) this.subscribers[event] = {}

    this.subscribers[event][id] = callback

    return {
      unregister: () => {
        delete this.subscribers[event][id]
        if (Object.keys(this.subscribers[event]).length === 0) {
          delete this.subscribers[event]
        }
      },
    }
  }

  private getNextId(): number {
    return EventBus.nextId++
  }
}

export const eventBus = {
  on(event: string, callback: any) {
    EventBus.getInstance().register(event, callback)
  },
  dispatch(event: string, data: any) {
    EventBus.getInstance().dispatch(event, data)
  },
  remove(event: string, callback?: any) {},
}
