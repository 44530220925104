'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { sendGTMEvent } from '@next/third-parties/google'
import { useToast } from '@vendure/shadcn/ui'

import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from '@vendure/shadcn/ui'
import { useForm } from 'react-hook-form'
import { MdArrowRight } from 'react-icons/md'
import { useIntl } from 'react-intl'
import { z } from 'zod'

interface PartnerFormProps {}

export const locations = [
  'north_america',
  'europe',
  'south_america',
  'middle_east',
  'asia',
  'africa',
  'oceania',
] as const
export const clientSizeFocus = ['small', 'mid_size', 'enterprise'] as const
export const averageProjectBudget = [
  'less_10k',
  '10k_50k',
  '50k_100k',
  '100k_500k',
  'over_500k',
] as const

export const PartnerFormSchema = z.object({
  // person and organization information
  first_name: z.string(),
  last_name: z.string(),
  company: z.string(),
  email: z.string().email(),
  location: z.enum(locations, {
    required_error: 'Please choose a location',
  }),
  website: z.string().url(),

  // partner information
  production_cases_description: z.string().optional(),
  production_case_link: z.string().url().optional(),
  development_cases_description: z.string().optional(),
  development_case_link: z.string().url().optional(),
  client_focus: z.enum(clientSizeFocus, {
    required_error: 'Please select your client size focus.',
  }),
  average_project_budget: z.enum(averageProjectBudget, {
    required_error: 'Please select your average project budget.',
  }),

  // form submission
  acceptance: z.boolean(),
})

export type PartnerFormSchemaType = z.infer<typeof PartnerFormSchema>

export function PartnerForm({}: PartnerFormProps) {
  const intl = useIntl()
  const { toast } = useToast()

  const form = useForm<PartnerFormSchemaType>({
    resolver: zodResolver(PartnerFormSchema),
  })

  const onSubmit = async (data: PartnerFormSchemaType) => {
    sendGTMEvent({
      event: 'partnerFormSubmit',
      value: data,
    })

    console.log({ data })

    try {
      const res = await fetch('/api/partner-form', {
        body: JSON.stringify(data),
        method: 'POST',
      })
      form.reset()
      toast({
        variant: 'success',
        description: intl.formatMessage({
          id: 'partner_form.success.description',
        }),
        title: intl.formatMessage({ id: 'shared.success.title' }),
      })
    } catch (error) {
      toast({
        variant: 'destructive',
        description: intl.formatMessage({
          id: 'partner_form.error.description',
        }),
        title: intl.formatMessage({ id: 'shared.error.title' }),
      })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {/* Person and organization information */}
        <div className="flex flex-col items-stretch gap-6">
          <div className="flex flex-col gap-4 md:flex-row">
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem className="basis-1/2">
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'partner_form.label.first_name',
                    })}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="John"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem className="basis-1/2">
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'partner_form.label.last_name',
                    })}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Doe"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({ id: 'partner_form.label.email' })}
                </FormLabel>
                <Input
                  {...field}
                  placeholder="john.doe@acme.com"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="company"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({ id: 'partner_form.label.company' })}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    placeholder="Acme Inc."
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="website"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({ id: 'partner_form.label.website' })}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="url"
                    placeholder="https://acme.com"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'partner_form.label.location',
                  })}
                </FormLabel>

                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select a location" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {locations.map((item) => (
                      <SelectItem
                        value={item}
                        key={item}
                      >
                        {intl.formatMessage({
                          id: `partner_form.location.${item}`,
                        })}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormDescription>
                  {intl.formatMessage({
                    id: 'partner_form.description.location',
                  })}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Partner information */}
          <FormField
            control={form.control}
            name="production_case_link"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'partner_form.label.production_case_link',
                  })}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="url"
                    placeholder={intl.formatMessage({
                      id: 'partner_form.placeholder.production_case_link',
                    })}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="production_cases_description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'partner_form.label.production_cases_description',
                  })}
                </FormLabel>

                <FormControl>
                  <Textarea
                    {...field}
                    placeholder={intl.formatMessage({
                      id: 'partner_form.placeholder.production_cases_description',
                    })}
                  />
                </FormControl>
                <FormDescription>
                  {intl.formatMessage({
                    id: 'partner_form.description.production_cases_description',
                  })}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="development_case_link"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'partner_form.label.development_case_link',
                  })}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="url"
                    placeholder={intl.formatMessage({
                      id: 'partner_form.placeholder.development_case_link',
                    })}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="development_cases_description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'partner_form.label.development_cases_description',
                  })}
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    placeholder={intl.formatMessage({
                      id: 'partner_form.placeholder.development_cases_description',
                    })}
                  />
                </FormControl>
                <FormDescription>
                  {intl.formatMessage({
                    id: 'partner_form.description.development_cases_description',
                  })}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="client_focus"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'partner_form.label.client_focus',
                  })}
                </FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select your client focus" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {clientSizeFocus.map((item) => (
                      <SelectItem
                        value={item}
                        key={item}
                      >
                        {intl.formatMessage({
                          id: `partner_form.client_focus.${item}`,
                        })}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormDescription>
                  {intl.formatMessage({
                    id: 'partner_form.description.client_focus',
                  })}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="average_project_budget"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'partner_form.label.average_project_budget',
                  })}
                </FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select your client focus" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {averageProjectBudget.map((item) => (
                      <SelectItem
                        value={item}
                        key={item}
                      >
                        {intl.formatMessage({
                          id: `partner_form.average_project_budget.${item}`,
                        })}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormDescription>
                  {intl.formatMessage({
                    id: 'partner_form.description.average_project_budget',
                  })}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="acceptance"
            render={({ field }) => (
              <FormItem className="space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'partner_form.label.acceptance',
                  })}
                </FormLabel>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormItem>
            <Button
              type="submit"
              variant="lead"
            >
              {intl.formatMessage({ id: 'partner_form.label.submit' })}{' '}
              <MdArrowRight className="h-6 w-6" />
            </Button>
          </FormItem>
        </div>
      </form>
    </Form>
  )
}
