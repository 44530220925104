import { getPrefixedLink } from '@vendure/marketing/util'
import type { ButtonStoryblok } from '@vendure/marketing/util-storyblok-types'
import { cn } from '@vendure/shadcn/util'
import type React from 'react'
import type { ComponentType, HTMLProps, PropsWithChildren } from 'react'
import { MdArrowRight } from 'react-icons/md'
import { CustomLink } from './CustomLink'

interface PreTitleProps extends PropsWithChildren {
  containerProps?: HTMLProps<HTMLDivElement>
  tag?: keyof JSX.IntrinsicElements | ComponentType
  button?: ButtonStoryblok
}

export const PreTitle: React.FC<PreTitleProps> = (props) => {
  const TextTag = props.tag ?? 'span'

  return (
    <div
      className={cn(
        `inline-flex items-center px-3 py-1 font-mono ${props.button ? 'pr-1' : ''} rounded-full border border-white/20 bg-white/10 text-xs font-bold text-white/60 transition-all duration-300`,
        props.containerProps?.className,
      )}
    >
      <TextTag className="block py-1 leading-none">{props.children}</TextTag>
      {props.button?.target && (
        <CustomLink
          href={getPrefixedLink(props.button.target)}
          className="hover:bg-primary-400 ml-2 flex items-center rounded-full bg-white/30 px-2 py-1 font-semibold transition-all"
        >
          {props.button.text}
          <MdArrowRight className="ml-1" />
        </CustomLink>
      )}
    </div>
  )
}
