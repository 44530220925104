import { graphql } from '@vendure/hub/util-vendure-types'

export const orderCustomerFragment = graphql(`
  fragment OrderCustomer on Customer {
    id
    emailAddress
    firstName
    lastName
    phoneNumber
  }
`)
