import { graphql } from '@vendure/hub/util-vendure-types'

export const assetFragment = graphql(`
  fragment Asset on Asset {
    id
    preview
    source
    mimeType
    width
    height
    focalPoint {
      x
      y
    }
  }
`)
