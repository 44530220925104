'use client'

import type { PersonStoryblok } from '@vendure/marketing/util-storyblok-types'
import type React from 'react'
import type { StoryblokStory } from 'storyblok-generate-ts'
import { GradientCard } from './GradientCard'
import { StoryblokImage } from './StoryblokImage'

interface HorizontalPersonCardProps {
  person:
    | StoryblokStory<PersonStoryblok>
    | Array<StoryblokStory<PersonStoryblok>>
}

export const HorizontalPersonCard: React.FC<HorizontalPersonCardProps> = ({
  person,
}) => {
  const people = Array.isArray(person) ? person : [person]

  return (
    <div className="flex items-center justify-start space-x-4">
      {people.map((person) => (
        <div
          key={person.uuid}
          className="flex items-center gap-4"
        >
          {person.content.image?.filename && (
            <GradientCard
              outerProps={{
                className: 'rounded-full w-10 h-10 overflow-hidden',
              }}
            >
              <StoryblokImage
                src={person.content.image.filename}
                alt={person.content.name ?? ''}
                image={person.content.image}
                style={{
                  objectFit: 'cover',
                  height: '100%',
                  width: '100%',
                }}
                width="80"
                height="80"
              />
            </GradientCard>
          )}
          <div>
            <p className="text-bold text-sm font-semibold text-white">
              {person.content.title && <span>{person.content.title} </span>}
              {person.content.name}
            </p>
            <p className="text-sm">{person.content.role}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
