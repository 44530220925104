import { graphql } from '@vendure/hub/util-vendure-types'
import { getAuthHeaders, serverApi } from '@vendure/shared/data-access-vendure'
import { VariablesOf } from 'gql.tada'

export const getAccountPageDataDoc = graphql(`
  query GetAccountPageData($licenseOptions: LicenseListOptions) {
    activeCustomer {
      id
      createdAt
      phoneNumber
      addresses {
        id
        city
      }
      title
      firstName
      lastName
      emailAddress
      organization {
        id
        name
        invoiceEmailAddresses
        contactEmailAddress
        taxId
        username
        password
        licenses(options: $licenseOptions) {
          items {
            id
            packageName
            expiresAt
            status
          }
          totalItems
        }
        stats {
          downloadCountWeekly
        }
        customFields {
          taxIdStatus
          verifiedAddress
        }
      }
    }
  }
`)

export async function getAccountPageData(
  variables: VariablesOf<typeof getAccountPageDataDoc>,
) {
  const headers = await getAuthHeaders()

  return serverApi.request(getAccountPageDataDoc, variables, headers)
}
