import { cn } from '@vendure/shadcn/util'
import type { ComponentType, HTMLProps, PropsWithChildren } from 'react'
import React, { ComponentProps } from 'react'
import { CustomLink } from '../atoms'
import IntrinsicElements = React.JSX.IntrinsicElements

interface GradientCardProps<
  T extends keyof IntrinsicElements | ComponentType = any,
> extends PropsWithChildren {
  as?: T
  outerProps?: ComponentProps<T>
  containerProps?: HTMLProps<HTMLDivElement>
  href?: string
}

export const GradientCard: React.FC<GradientCardProps> = ({
  children,
  as = 'div',
  containerProps,
  outerProps,
  href,
}) => {
  const Wrapper = as as keyof JSX.IntrinsicElements | ComponentType

  return (
    <Wrapper
      style={outerProps?.style}
      className={cn(
        'from-primary-300/40 block bg-gradient-to-b to-slate-800/30 p-[1px]',
        'h-full overflow-hidden rounded-md ',
        outerProps?.className,
      )}
      {...(as === CustomLink
        ? {
            href,
          }
        : {})}
    >
      <div
        className={cn(
          'block h-full overflow-hidden rounded-[inherit] bg-gradient-to-b from-slate-900 to-slate-950 transition-all',
          as === CustomLink ? ' bg-slate-900 hover:bg-slate-800' : '',
          containerProps?.className,
        )}
      >
        {children}
      </div>
    </Wrapper>
  )
}
