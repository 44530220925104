'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  useToast,
} from '@vendure/shadcn/ui'
import { useRouter } from 'next/navigation'
import { useForm } from 'react-hook-form'
import { CgSpinner } from 'react-icons/cg'
import { useIntl } from 'react-intl'
import { z } from 'zod'
import { updatePassword } from './actions'

export const PasswordResetForm = ({ token }: { token: string }) => {
  const { toast } = useToast()
  const router = useRouter()
  const intl = useIntl()

  const FormSchema = z.object({
    password1: z.string().min(8),
    password2: z.string().min(8),
  })
  const form = useForm<z.infer<typeof FormSchema>>({
    defaultValues: {
      password1: '',
      password2: '',
    },
    resolver: zodResolver(FormSchema),
  })

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    const res = await updatePassword(token, data.password1)

    switch (res.resetPassword.__typename) {
      case 'NativeAuthStrategyError':
      case 'NotVerifiedError':
      case 'PasswordValidationError':
      case 'PasswordResetTokenExpiredError':
      case 'PasswordResetTokenInvalidError':
        toast({
          title: intl.formatMessage({
            id: 'hub.error.could_not_reset_password',
          }),
          description: res.resetPassword.message,
          variant: 'destructive',
        })
        break
      case 'CurrentUser':
        toast({
          title: intl.formatMessage({
            id: 'hub.message.password_was_updated',
          }),
          variant: 'success',
        })
        router.replace('/account')
        break
    }
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div>
            <div className="text-xl font-bold text-white">
              Update your password
            </div>
          </div>
          <div className="mt-4 grid gap-4">
            <FormField
              control={form.control}
              name="password1"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'hub.label.password',
                    })}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password2"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'hub.label.repeat_password',
                    })}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormItem>
              <Button
                className="mt-8 w-full"
                variant="default"
                type="submit"
                disabled={
                  form.getValues('password1') !== form.getValues('password2')
                }
              >
                {form.formState.isSubmitting ? (
                  <CgSpinner className="animate-spin" />
                ) : (
                  intl.formatMessage({ id: 'hub.label.update_password' })
                )}
              </Button>
            </FormItem>
          </div>
        </form>
      </Form>
    </div>
  )
}
