'use client'

import { CaretDownIcon } from '@radix-ui/react-icons'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@vendure/shadcn/ui'
import { CustomLink } from '@vendure/shared/ui'
import { useActiveCustomer } from '@vendure/user-account/ui'
import { AnimatePresence, motion } from 'motion/react'
import Link from 'next/link'
import { HiUser } from 'react-icons/hi'
import { SignOutButton } from './sign-out-button'

export function AccountDropdown() {
  const { activeCustomer } = useActiveCustomer()

  const variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  }

  return (
    <AnimatePresence>
      {activeCustomer && (
        <motion.div variants={variants}>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="sm"
              >
                {activeCustomer?.firstName} {activeCustomer?.lastName}
                <CaretDownIcon></CaretDownIcon>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mt-1">
              <DropdownMenuItem
                asChild
                className="cursor-pointer"
              >
                <CustomLink href="/account">Account</CustomLink>
              </DropdownMenuItem>
              <DropdownMenuItem
                asChild
                className="cursor-pointer"
              >
                <CustomLink href="/account/settings">Settings</CustomLink>
              </DropdownMenuItem>
              <DropdownMenuItem className="cursor-pointer">
                <SignOutButton />
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </motion.div>
      )}
      {!activeCustomer && (
        <motion.div variants={variants}>
          <Button
            asChild
            variant="ghost"
            className="flex items-center gap-2"
          >
            <Link href="/sign-in">
              <HiUser />
              Sign in
            </Link>
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
