import {
  Button,
  ScrollArea,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@vendure/shadcn/ui'
import { cn } from '@vendure/shadcn/util'
import moment from 'moment-timezone'
import Link from 'next/link'
import { Fragment } from 'react'
import { FormattedDate, GradientCard, Heading, StoryblokImage } from '../atoms'

export interface AgendaSlot {
  start: moment.Moment
  end: moment.Moment
  title?: string
  subTitle?: string
  onClick?: () => void
  active?: boolean
  speakers?: Array<{
    image?: string
    name?: string
    role?: string
  }>
  payment?: {
    link?: string
    seats?: number
    price?: number
  }
}

export interface AgendaLane {
  title?: string
  date?: Date
  slots?: AgendaSlot[]
  bundle?: {
    link: string
    text?: string
  }
}

interface AgendaProps {
  lanes?: AgendaLane[]
  startHour: Date
  endHour: Date
}

function getHourRange(start: Date, end: Date) {
  end.setDate(start.getDate())
  end.setMonth(start.getMonth())

  return moment(end).diff(moment(start), 'hours')
}

export function Agenda({ lanes, startHour, endHour }: AgendaProps) {
  const start = startHour.getHours()
  const end = endHour.getHours()

  // hours span
  const hoursRange = getHourRange(startHour, endHour)
  const maxSlots = hoursRange * 12

  return (
    <ScrollArea>
      <div
        style={{
          gridTemplateRows: `150px repeat(${maxSlots},minmax(0,20px))`,
          gridTemplateColumns: `50px repeat(${lanes?.length}, minmax(300px, 1fr))`,
        }}
        className="grid grid-flow-col overflow-x-scroll md:overflow-x-visible"
      >
        {[...Array(hoursRange)].map((_, i) => {
          return (
            <div
              key={`hour-${i}`}
              className={cn({
                'row-span-12 flex  items-start justify-end pr-2': true,
                'row-start-2': i === 0,
              })}
            >
              <span className="-translate-y-1/2">{start + 1 + i}:00</span>
            </div>
          )
        })}
        {lanes?.map((lane, i) => {
          let totalSlotsRendered = 0
          return (
            <Fragment key={`lane-${i}`}>
              <div
                className={cn({
                  'flex flex-col space-y-2 p-4 lg:flex-row lg:items-center lg:justify-between lg:space-y-0':
                    true,
                })}
              >
                <div>
                  <Heading
                    as="span"
                    className="block text-xl"
                  >
                    {lane.title}
                  </Heading>
                  <FormattedDate
                    date={lane.date}
                    className="block"
                  />
                </div>
                {lane.bundle && (
                  <Button
                    asChild
                    variant="lead"
                  >
                    <Link href={lane.bundle.link}>
                      {lane.bundle.text ?? 'Buy bundle'}
                    </Link>
                  </Button>
                )}
              </div>
              {lane.slots?.map((item, l) => {
                const diff = moment(item.end).diff(
                  moment(item.start),
                  'minutes',
                )
                const spans = Math.floor(diff / 5)
                totalSlotsRendered += spans

                return (
                  <div
                    style={{
                      gridRow: `span ${spans} / span ${spans}`,
                    }}
                    className={cn({
                      'border-t border-dashed border-slate-800 p-2 ': true,
                      'border-r': i === 0,
                    })}
                    key={`lane-${i}-slot-${l}`}
                  >
                    <div
                      className={cn({
                        'hover:shadow-lead-xl hover:border-primary-400 relative h-full rounded-md border-2 border-transparent bg-slate-900 px-4 transition-all':
                          true,
                        'py-3': spans > 4,
                        'border-primary-400 shadow-lead-xl': item.active,
                        'flex items-center': spans <= 4,
                        'cursor-pointer': typeof item.onClick === 'function',
                      })}
                      onClick={() => item.onClick?.()}
                    >
                      <div className="flex items-start justify-between">
                        <div>
                          <span className="block font-semibold text-white">
                            {item.title}
                          </span>
                          {item.subTitle && (
                            <span className="mb-1 block text-sm">
                              ({item.start.format('HH:mm')}-
                              {item.end.format('HH:mm')}) {item.subTitle}
                            </span>
                          )}
                        </div>

                        {item.speakers && item.speakers.length > 0 && (
                          <div className="mt-1 flex gap-2">
                            {item.speakers.map((speaker) => {
                              return (
                                <TooltipProvider
                                  key={`tooltip-${speaker.name}`}
                                >
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <GradientCard
                                        outerProps={{
                                          className:
                                            'h-10 w-10 overflow-hidden rounded-full',
                                        }}
                                      >
                                        {speaker.image && (
                                          <StoryblokImage
                                            width={100}
                                            height={0}
                                            className="h-full w-full object-cover object-center"
                                            src={speaker.image}
                                            alt={speaker.name ?? ''}
                                          ></StoryblokImage>
                                        )}
                                      </GradientCard>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <p>{speaker.name}</p>
                                      <p className="text-xs">{speaker.role}</p>
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              )
                            })}
                          </div>
                        )}
                      </div>
                      {item.payment && (
                        <div className="border-1 mt-10 inline-block space-y-2 rounded-lg border-dashed border-white/40 bg-white/10 p-4">
                          <p className="text-2xl font-bold">
                            <span className="text-white">
                              {Number(item.payment.price)} EUR
                            </span>
                            <span className="ml-2 text-xs">
                              / seat excl. VAT
                            </span>
                          </p>
                          {item.payment.link && (
                            <Button
                              asChild
                              variant="lead"
                            >
                              <Link
                                target="_blank"
                                href={item.payment.link}
                              >
                                Secure seat now
                              </Link>
                            </Button>
                          )}
                          <p className="italic">
                            Limited to only {item.payment.seats} seats. <br />
                            First come, first served. Act fast!
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
              {totalSlotsRendered < maxSlots && (
                <div
                  key={`lane-${i}-slot-${lane.slots?.length}`}
                  className={cn({
                    'border-t border-dashed border-slate-800 p-2 ': true,
                  })}
                  style={{
                    gridRow: `span ${maxSlots - totalSlotsRendered} / span ${maxSlots - totalSlotsRendered}`,
                  }}
                ></div>
              )}
            </Fragment>
          )
        })}
      </div>
    </ScrollArea>
  )
}
