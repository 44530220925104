import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { RichtextStoryblok } from '@vendure/marketing/util-storyblok-types'
import {
  CopyBlock,
  CustomLink,
  StoryblokImage,
  tomorrowNightBlue,
} from '@vendure/shared/ui'
import Link from 'next/link'
import { FiArrowUpRight } from 'react-icons/fi'
import {
  MARK_LINK,
  NODE_CODEBLOCK,
  NODE_IMAGE,
  render as _render,
} from 'storyblok-rich-text-react-renderer'
import { removeColorProperty } from './remove-colors-helper'

export function render(document: RichtextStoryblok) {
  return _render(removeColorProperty(document), {
    markResolvers: {
      [MARK_LINK]: (children, props) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (props === undefined) {
          // Internal links: map to <Link>
          return (
            <CustomLink
              href=""
              passHref
              legacyBehavior
            >
              <a>{children}</a>
            </CustomLink>
          )
        }
        const { linktype, href, target } = props

        if (linktype === 'email') {
          // Email links: add `mailto:` scheme and map to <a>
          return <Link href={`mailto:${href ?? ''}`}>{children}</Link>
        }
        if (href?.match(/^(https?:)?\/\//)) {
          // External links: map to <a>
          return (
            <CustomLink
              className="relative inline-flex items-center font-semibold text-white/80 underline"
              href={href}
              target={target}
            >
              {children}
              <FiArrowUpRight />
            </CustomLink>
          )
        }
        // Internal links: map to <Link>
        return (
          <CustomLink
            href={href ?? ''}
            className={
              'text-primary-400 hover:text-primary-300 underline transition-colors'
            }
          >
            {children}
          </CustomLink>
        )
      },
    },
    nodeResolvers: {
      [NODE_CODEBLOCK]: (children, props) => {
        const language = props.class
          ? props.class.replace('language-', '')
          : 'text'

        return (
          <div className="my-6">
            <CopyBlock
              language={language}
              theme={tomorrowNightBlue}
              text={children}
            />
          </div>
        )
      },
      [NODE_IMAGE]: (children, { src, alt, title }) => {
        if (!src) {
          return null
        }

        return (
          <StoryblokImage
            src={src}
            width={900}
            height={0}
            className="mx-auto my-10 h-auto w-full rounded-md"
            alt={alt ?? ''}
          />
        )
      },
    },
    defaultBlokResolver(name, props) {
      const blok = {
        component: name,
        ...props,
      }
      return (
        <StoryblokServerComponent
          blok={blok}
          key={blok._uid}
        />
      )
    },
  })
}
