import { FullWidthImageStoryblok } from '@vendure/marketing/util-storyblok-types'
import { GradientCard, StoryblokImage } from '@vendure/shared/ui'

interface IFullWidthImage {
  blok: FullWidthImageStoryblok
}

export function FullWidthImage({ blok }: IFullWidthImage) {
  return (
    <GradientCard outerProps={{ className: 'w-full' }}>
      {blok.imageMobile?.filename && (
        <StoryblokImage
          width={1500}
          height={800}
          className="block h-auto w-full md:hidden"
          src={blok.imageMobile.filename}
          alt={blok.imageMobile.alt ?? ''}
        />
      )}
      {blok.imageDesktop?.filename && (
        <StoryblokImage
          width={1500}
          height={800}
          className="hidden h-auto w-full md:block"
          src={blok.imageDesktop.filename}
          alt={blok.imageDesktop.alt ?? ''}
        />
      )}
    </GradientCard>
  )
}
