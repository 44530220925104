export const AUTH_COOKIE_NAME = 'vendure-auth-token'
export const VENDURE_HUB_URL =
  process.env.USE_DEV_VENDURE_SERVER === 'true'
    ? process.env.VENDURE_HUB_URL_DEV ??
      process.env.NEXT_PUBLIC_VENDURE_HUB_URL_DEV!
    : process.env.VENDURE_HUB_URL ?? process.env.NEXT_PUBLIC_VENDURE_HUB_URL!

export const LocalStorageKey = {
  redirectAfterAccountSetup: 'redirectAfterAccountSetup',
}
