'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { KEY_ACTIVE_CUSTOMER } from '@vendure/hub/data-access'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@vendure/shadcn/ui'
import Link from 'next/link'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { z } from 'zod'
import { performSignIn } from './actions'

export function SignInForm({
  redirectOnSuccess,
}: {
  redirectOnSuccess?: string
}) {
  const intl = useIntl()
  const queryClient = useQueryClient()

  const FormSchema = z.object({
    username: z.string().min(1),
    password: z.string().min(1),
  })
  const form = useForm<z.infer<typeof FormSchema>>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: zodResolver(FormSchema),
  })

  async function onSubmit(values: z.infer<typeof FormSchema>) {
    await performSignIn(values.username, values.password, true)
    await queryClient.invalidateQueries({ queryKey: KEY_ACTIVE_CUSTOMER })
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div>
            <div className="text-xl font-bold text-white">
              {intl.formatMessage({ id: 'hub.label.sign_in' })}
            </div>
          </div>
          <div className="mt-4 grid gap-4">
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'hub.label.email',
                    })}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'hub.label.password',
                    })}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mt-2 text-right text-sm">
            <Link
              href="/forgot-password"
              className="underline"
            >
              Forgot password?
            </Link>
          </div>
          <div>
            <FormItem>
              <Button
                className="mt-8 w-full"
                variant="default"
                type="submit"
                disabled={!form.formState.isValid}
              >
                {intl.formatMessage({ id: 'hub.label.sign_in' })}
              </Button>
            </FormItem>
          </div>
        </form>
        <div className="mt-4 text-center text-sm">
          Don&apos;t have an account?
          <Link
            href="/sign-up"
            className="ml-1 underline"
          >
            Sign up
          </Link>
        </div>
      </Form>
    </div>
  )
}
