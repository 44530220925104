'use client'

import { assetFragment } from '@vendure/hub/data-access'
import { cn } from '@vendure/shadcn/util'
import { ResultOf } from 'gql.tada'
import Image from 'next/image'
import { useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
import { FreeMode, Navigation, Thumbs, Zoom } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { StoryblokImage } from '../atoms'

export interface AssetGalleryProps<T> {
  featuredAsset?: T | null
  assets: T[]
}

export const AssetGallery = <T extends ResultOf<typeof assetFragment>>(
  props: AssetGalleryProps<T>,
) => {
  const usingFeaturedAsset = props.featuredAsset && props.assets.length === 1
  const displayAssets = usingFeaturedAsset
    ? props.assets
    : props.assets.filter((asset) => asset.id !== props.featuredAsset?.id)

  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const featuredAsset = props.featuredAsset
  const mainImageQueryParams =
    usingFeaturedAsset && featuredAsset
      ? `h=800&w=800&q=90&mode=crop&fpy=${featuredAsset.focalPoint?.y}&fpx=${featuredAsset.focalPoint?.x}&format=webp`
      : 'h=900&w=1200&q=95&mode=resize'

  return (
    <div className="mx-auto max-w-screen-xl px-1 md:px-0">
      <Swiper
        slidesPerView={1}
        modules={[Thumbs, Navigation, FreeMode, Zoom]}
        zoom={true}
        spaceBetween={10}
        centeredSlides={true}
        style={{
          // @ts-ignore
          '--swiper-theme-color': 'rgb(6 177 241 / var(--tw-bg-opacity))',
        }}
        thumbs={{ swiper: thumbsSwiper }}
        onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {displayAssets.map((asset, i) => (
          <SwiperSlide key={asset.id}>
            <div className="swiper-zoom-container flex aspect-square items-center">
              <Image
                width={usingFeaturedAsset ? 250 : 500}
                height={300}
                src={`${asset.preview}?${mainImageQueryParams}`}
                alt={`Image ${i + 1} of ${displayAssets.length}`}
                className={cn(
                  'w-full rounded',
                  usingFeaturedAsset ? 'lg:mt-10' : '',
                )}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {displayAssets.length > 1 && (
        <div className="mt-4">
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={5}
            slidesPerView={5}
            watchSlidesProgress={true}
            modules={[Navigation, Thumbs]}
          >
            {displayAssets.map((asset, i) => (
              <SwiperSlide key={asset.id}>
                <StoryblokImage
                  width={90}
                  height={50}
                  src={`${asset.preview}?h=90&w=90&mode=resize`}
                  alt={`Image ${i + 1} of ${displayAssets.length}`}
                  className={cn({
                    'aspect-square rounded object-cover object-center transition-colors':
                      true,
                    'border-primary-500 border-2': activeIndex === i,
                    'border-2 border-gray-200 opacity-80': activeIndex !== i,
                  })}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  )
}
