'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { register } from '@vendure/hub/data-access'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  useToast,
} from '@vendure/shadcn/ui'
import Link from 'next/link'
import { parseAsString, useQueryState } from 'nuqs'
import { useForm } from 'react-hook-form'
import { CgSpinner } from 'react-icons/cg'
import { useIntl } from 'react-intl'
import { z } from 'zod'

const FormSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  organizationName: z.string().optional(),
  username: z.string().min(1),
  password: z.string().min(8),
  passwordCheck: z.string().min(8),
})

export function SignUpForm() {
  const intl = useIntl()
  const { toast } = useToast()
  const [pendingEmailAddress, setPendingEmailAddress] = useQueryState(
    'pendingEmailAddress',
    parseAsString,
  )

  const registerMutation = useMutation({
    mutationFn: register,
    onSuccess: (data, vars) => {
      switch (data.registerCustomerAccount.__typename) {
        case 'PasswordValidationError':
        case 'NativeAuthStrategyError':
        case 'MissingPasswordError':
          toast({
            title: intl.formatMessage({ id: 'hub.error.could_not_sign_up' }),
            description: data.registerCustomerAccount.message,
            variant: 'destructive',
          })
          break
        case 'Success':
          setPendingEmailAddress(vars.input!.emailAddress)
          break
      }
    },
  })
  const form = useForm<z.infer<typeof FormSchema>>({
    defaultValues: {
      firstName: '',
      lastName: '',
      organizationName: '',
      username: '',
      password: '',
      passwordCheck: '',
    },
    resolver: zodResolver(FormSchema),
  })

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    registerMutation.mutate({
      input: {
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.username,
        password: data.password,
        customFields: {
          organizationName: data.organizationName,
        },
      },
    })
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="text-xl font-bold">
          {intl.formatMessage({ id: 'hub.label.sign_up' })}
        </div>
        <div className="mt-4 grid gap-4">
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'hub.label.first_name',
                    })}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'hub.label.last_name',
                    })}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="organizationName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'hub.label.organization_name',
                  })}
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormDescription>
                  This should be the registered company name. If you are an
                  individual, you can leave it blank.
                </FormDescription>
                <FormMessage></FormMessage>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'hub.label.email',
                  })}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="email"
                  />
                </FormControl>
                <FormMessage></FormMessage>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'hub.label.password',
                  })}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="password"
                  />
                </FormControl>
                {form.getFieldState('password').isTouched &&
                  form.formState.errors?.password && (
                    <FormMessage className="text-red-600">
                      Password must be at least 8 characters
                    </FormMessage>
                  )}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="passwordCheck"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {intl.formatMessage({
                    id: 'hub.label.repeat_password',
                  })}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="password"
                  />
                </FormControl>
                {form.getFieldState('passwordCheck').isTouched &&
                  form.getValues('password') !==
                    form.getValues('passwordCheck') && (
                    <FormMessage className="text-red-600">
                      Passwords do not match
                    </FormMessage>
                  )}
              </FormItem>
            )}
          />
          <FormItem>
            <Button
              type="submit"
              variant={'default'}
              className="w-full"
              disabled={
                !form.formState.isValid ||
                form.getValues('password') !== form.getValues('passwordCheck')
              }
            >
              {registerMutation.isPending ? (
                <CgSpinner className="animate-spin" />
              ) : (
                intl.formatMessage({ id: 'hub.label.create_a_new_account' })
              )}
            </Button>
          </FormItem>
        </div>
        <div className="mt-4 text-center text-sm">
          Already have an account?
          <Link
            href="/sign-in"
            className="ml-1 underline"
          >
            Sign in
          </Link>
        </div>
      </form>
    </Form>
  )
}
