import { graphql } from '@vendure/hub/util-vendure-types'
import { clientApi } from '@vendure/shared/data-access-vendure'
import { VariablesOf } from 'gql.tada'
import { activeOrderFragment } from '../fragments/active-order'

export const addItemToOrderDoc = graphql(
  `
    mutation AddItemToOrder(
      $productVariantId: ID!
      $quantity: Int!
      $customFields: OrderLineCustomFieldsInput
    ) {
      addItemToOrder(
        productVariantId: $productVariantId
        quantity: $quantity
        customFields: $customFields
      ) {
        __typename
        ...ActiveOrder
        ... on ErrorResult {
          errorCode
          message
        }
        ... on InsufficientStockError {
          quantityAvailable
          order {
            ...ActiveOrder
          }
        }
      }
    }
  `,
  [activeOrderFragment],
)

export async function addItemToOrder(variables: {
  variantId: string
  quantity: number
  customFields: VariablesOf<typeof addItemToOrderDoc>['customFields']
}) {
  return clientApi.request(addItemToOrderDoc, {
    productVariantId: variables.variantId,
    quantity: variables.quantity,
    customFields: variables.customFields,
  })
}
