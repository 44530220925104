import { graphql } from '@vendure/hub/util-vendure-types'
import { serverApi } from '@vendure/shared/data-access-vendure'
import { ResultOf, VariablesOf } from 'gql.tada'
import { print } from 'graphql'

const resetPasswordDoc = graphql(`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      __typename
      ... on CurrentUser {
        id
        identifier
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`)

export async function resetPassword(
  variables: VariablesOf<typeof resetPasswordDoc>,
) {
  return serverApi.rawRequest<ResultOf<typeof resetPasswordDoc>>(
    print(resetPasswordDoc),
    variables,
  )
}
