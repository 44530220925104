'use client'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@vendure/shadcn/ui'

interface ICaseStudyGridFilter {
  categoryOptions: string[]
  locationOptions: string[]
  selectedCategories: string[]
  selectedLocations: string[]
  onFilterChange: (
    selectedCategories: string[],
    selectedLocations: string[],
  ) => void
}

export const FilterChoice: React.FC<{
  label: string
  options: string[]
  selected: string[]
  onChange: (newValues: string[]) => void
}> = ({ options, selected, onChange, label }) => {
  return (
    <Select
      onValueChange={(newValues) => {
        onChange([newValues])
      }}
    >
      <SelectTrigger>
        <SelectValue placeholder={label} />
      </SelectTrigger>
      <SelectContent>
        {options.map((item) => {
          return (
            <SelectItem
              value={item}
              key={item}
            >
              {item}
            </SelectItem>
          )
        })}
      </SelectContent>
    </Select>
  )
}

export const CaseStudyGridFilter: React.FC<ICaseStudyGridFilter> = ({
  categoryOptions,
  locationOptions,
  selectedCategories,
  selectedLocations,
  onFilterChange,
}) => {
  return (
    <div className="flex items-center justify-start gap-2">
      <span className="font-semibold text-white/80">Filter</span>
      <FilterChoice
        label="Category"
        options={categoryOptions}
        selected={selectedCategories}
        onChange={(values) => {
          onFilterChange(values, selectedLocations)
        }}
      />
      <FilterChoice
        label="Location"
        options={locationOptions}
        selected={selectedLocations}
        onChange={(values) => {
          onFilterChange(selectedCategories, values)
        }}
      />
    </div>
  )
}
