'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { sendGTMEvent } from '@next/third-parties/google'
import { Tabs, TabsList, TabsTrigger, useToast } from '@vendure/shadcn/ui'

import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from '@vendure/shadcn/ui'
import { locations } from '@vendure/shared/util'
import {
  parseAsArrayOf,
  parseAsString,
  parseAsStringEnum,
  useQueryState,
} from 'nuqs'
import { useForm } from 'react-hook-form'
import { MdArrowRight } from 'react-icons/md'
import { useIntl } from 'react-intl'
import { z } from 'zod'
import { GradientCard, PreventHubspotFormTracking } from '../atoms'

interface ContactFormProps {
  topic?: string
}

export const interestedInOptions = [
  'enterprise_edition',
  'dedicated_support',
  'guided_demo',
  'partnership',
  'finding_implementation_partner',
  'publish_paid_plugins',
  'other',
] as const

enum EntityTypes {
  Agency = 'agency',
  Corporation = 'corporation',
}

export const entityTypeOptions = ['agency', 'corporation'] as const

export const FormSchema = z.object({
  entity_type: z.enum(entityTypeOptions, {
    required_error: 'You need to select what type of organization you are.',
  }),
  interested_in: z
    .array(z.string())
    .refine((value) => value.some((item) => item), {
      message: 'You have to select at least one item.',
    }),
  first_name: z.string(),
  last_name: z.string(),
  company: z.string(),
  email: z.string().email(),
  location: z.enum(locations, {
    required_error: 'Please choose a location',
  }),
  use_case: z.string().optional(),
  acceptance: z.boolean(),
})

export const ContactForm: React.FC<ContactFormProps> = ({ topic }) => {
  const intl = useIntl()
  const { toast } = useToast()
  const [interestedIn] = useQueryState(
    'interested_in',
    parseAsArrayOf(parseAsString),
  )
  type FormSchemaType = z.infer<typeof FormSchema>
  const [entityType] = useQueryState(
    'entity_type',
    parseAsStringEnum<EntityTypes>(Object.values(EntityTypes)),
  )

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      interested_in: interestedIn ?? [],
      entity_type: entityType ?? undefined,
    },
  })

  const onSubmit = async (data: FormSchemaType) => {
    sendGTMEvent({
      event: 'contactFormSubmit',
      value: data,
    })

    try {
      const res = await fetch('/api/contact-form', {
        body: JSON.stringify(data),
        method: 'POST',
      })
      form.reset()
      toast({
        variant: 'success',
        description: intl.formatMessage({
          id: 'contact_form.success.description',
        }),
        title: intl.formatMessage({ id: 'shared.success.title' }),
      })
    } catch (error) {
      toast({
        variant: 'destructive',
        description: intl.formatMessage({
          id: 'contact_form.error.description',
        }),
        title: intl.formatMessage({ id: 'shared.error.title' }),
      })
    }
  }

  return (
    <GradientCard containerProps={{ className: 'p-4 md:p-8 bg-slate-900' }}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <PreventHubspotFormTracking></PreventHubspotFormTracking>
          <div className="flex flex-col items-stretch gap-4">
            <FormField
              control={form.control}
              name="entity_type"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Tabs onValueChange={(value) => field.onChange(value)}>
                      <TabsList>
                        <TabsTrigger value="corporation">
                          {intl.formatMessage({
                            id: 'contact_form.label.corporation',
                          })}
                        </TabsTrigger>
                        <TabsTrigger value="agency">
                          {intl.formatMessage({
                            id: 'contact_form.label.agency',
                          })}
                        </TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="interested_in"
              render={({ field }) => (
                <FormItem className="space-y-4">
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'contact_form.label.interested_in',
                    })}
                  </FormLabel>
                  <div className="flex flex-col gap-2">
                    {interestedInOptions.map((item) => (
                      <FormField
                        key={item}
                        control={form.control}
                        name="interested_in"
                        render={({ field }) => {
                          const onCheckedChange = (checked: boolean) => {
                            checked
                              ? field.onChange([...field.value, item])
                              : field.onChange(
                                  field.value.filter((value) => value !== item),
                                )
                          }

                          return (
                            <FormItem
                              key={item}
                              className="flex flex-row items-start space-x-3 space-y-0 rounded-lg border border-slate-700 bg-slate-800 px-2 py-3 font-medium transition-colors hover:bg-slate-700"
                            >
                              <FormControl>
                                <Checkbox
                                  checked={field.value.includes(item)}
                                  onCheckedChange={onCheckedChange}
                                />
                              </FormControl>
                              <FormLabel className="cursor-pointer">
                                {intl.formatMessage({
                                  id: `contact_form.label.${item}`,
                                })}
                              </FormLabel>
                            </FormItem>
                          )
                        }}
                      />
                    ))}
                  </div>
                  <FormDescription>
                    {intl.formatMessage({
                      id: 'contact_form.helper.interested_in',
                    })}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="company"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {intl.formatMessage({ id: 'contact_form.label.company' })}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Acme Inc."
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col gap-4 md:flex-row">
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem className="basis-1/2">
                    <FormLabel>
                      {intl.formatMessage({
                        id: 'contact_form.label.first_name',
                      })}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="John"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem className="basis-1/2">
                    <FormLabel>
                      {intl.formatMessage({
                        id: 'contact_form.label.last_name',
                      })}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Doe"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {intl.formatMessage({ id: 'contact_form.label.email' })}
                  </FormLabel>
                  <Input
                    {...field}
                    placeholder="john.doe@acme.com"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'contact_form.label.location',
                    })}
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a location" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {locations.map((item) => (
                        <SelectItem
                          value={item}
                          key={item}
                        >
                          {intl.formatMessage({
                            id: `contact_form.location.${item}`,
                          })}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    {intl.formatMessage({
                      id: 'contact_form.helper.location',
                    })}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="use_case"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'contact_form.label.use_case',
                    })}
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder={intl.formatMessage({
                        id: 'contact_form.placeholder.use_case',
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="acceptance"
              render={({ field }) => (
                <FormItem className="space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>
                    {intl.formatMessage({
                      id: 'contact_form.label.acceptance',
                    })}
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormItem>
              <Button
                type="submit"
                variant="lead"
              >
                {intl.formatMessage({ id: 'contact_form.label.submit' })}{' '}
                <MdArrowRight className="h-6 w-6" />
              </Button>
            </FormItem>
          </div>
        </form>
      </Form>
    </GradientCard>
  )
}
